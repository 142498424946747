export const typeEnergy = [
  { text: "Energía activa de salida (kWh)", value: "AS" },
  { text: "Energía activa de entrada (kWh)", value: "AE" },
  { text: "Energía reactiva capacitiva (kVArh)", value: "R1" },
  { text: "Energía reactiva inductiva (kVArh)", value: "R2" },
  { text: "Energía reactiva inductiva penalizada (kVArh)", value: "R2P" }
];
export const unityEnergy = {
  AS: "kWh",
  AE: "kVArh",
  R1: "kVArh",
  R2: "kVArh",
  R2P: "kVArh"
};
