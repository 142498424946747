import React from 'react';

const SvgComponent = props => (
  <svg width="100%" height={13.845} {...props}>
    <path fill="#2f3687" d="M1920 0v13.845h-274.286V0z" />
    <path fill="#73a6b1" d="M1645.714 0v13.845h-274.286V0z" />
    <path fill="#9a2423" d="M1371.429 0v13.845h-274.286V0z" />
    <path fill="#dc8f35" d="M1097.143 0v13.845H822.857V0z" />
    <path fill="#f3ce11" d="M822.857 0v13.845H548.571V0z" />
    <path fill="#458c87" d="M548.571 0v13.845H274.285V0z" />
    <path fill="#30385c" d="M274.286 0v13.845H0V0z" />
  </svg>
);

export default SvgComponent;
