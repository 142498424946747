import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  Box,
  Grid,
  TableBody,
  TableCell,
  Typography,
  makeStyles
} from "@material-ui/core";
import DatePicker from "../DatePicker";
import { moment } from "../../utils/Helper";
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  BarChart
} from "recharts";
import AppTable from "../table/AppTable";
import AppTableRow from "../table/AppTableRow";
import TableCellHead from "../table/TableCellHead";
import BasePaper from "./BasePaper";
import FiltersHeadline from "./FiltersHeadline";
import api from "../../services/api";
import {
  formatDate,
  getYear,
  getMonth,
  subtractDays,
  getCurrentDate
} from "../../modules/date";
import { typeEnergy, unityEnergy } from "../../constants/energy";

import ConsumptionTable from "../ConsumptionTable";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

const useStyles = makeStyles(theme => ({
  chart: {
    height: 300,
    width: "100%"
  },
  content: {
    width: "100%"
  }
}));

const MonthlyConsumption = ({ nic, origin }) => {
  const classes = useStyles();
  let currentDate = getCurrentDate();
  let [startSelectedDate, setStartSelectedDate] = useState(
    moment(currentDate).subtract(4, "months")
  );
  let [_origin, setOrigin] = useState(null);
  let [endSelectedDate, setEndSelectedDate] = useState(currentDate);
  let legend = `${moment()
    .format("MMMM")
    .toUpperCase()} ${moment()
    .format("YYYY")
    .toUpperCase()}`;
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(false);
  let [type, setType] = useState(typeEnergy[0].value.toLowerCase());
  const [consumptionDataTable, setConsumptionDataTable] = useState([]);
  const [consumptionData, setConsumptionData] = useState([]);
  const handleTypeChange = e => {
    let { value } = e.target;
    /*  value = value.toUpperCase(); */
    setType(value);
    fetchMonthlyConsumption(value, origin);
  };
  const hasResultInConsumptionResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetConsumoMesResponse"][
        "GetConsumoMesResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };
  const getType = type => {
    let energyType = typeEnergy.filter(item => item.value === type);
    if (energyType.length > 0) return energyType[0].text;
    return "";
  };
  const getMonths = (startSelectedDate, endSelectedDate) => {
    let start = moment(startSelectedDate);
    let end = moment(endSelectedDate);
    let month_names = [];
    let newDate = start;
    do {
      let legend = `${newDate.format("MMMM").toUpperCase()}-${newDate
        .format("YYYY")
        .toUpperCase()}`;
      /* if (month_names.indexOf(legend) === -1) month_names.push(legend); */
      newDate = start.add(1, "month");
      start = newDate;
    } while (start < end);
    /* setMonths(month_names); */
  };
  const getResultInConsumptionResponse = response => {
    return response["s:Envelope"]["s:Body"]["GetConsumoMesResponse"][
      "GetConsumoMesResult"
    ]["a:Consumos"]["a:ConsumoMes"];
  };
  const normalizeConsumptionData = (consumption = []) => {
    console.log("normalize consumption data.. ", consumption);
    if (Array.isArray(consumption)) {
      /* let accumulated = 0; */
      return consumption.map(item => {
        /* accumulated += parseFloat(item["a:TOTAL_MES"]["_text"]); */
        return {
          name: moment(
            `${item["a:MES"]["_text"]} - ${item["a:ANNO"]["_text"]}`,
            "M - YYYY"
          ).format("MMMM [de] YYYY"),
          value: item["a:TOTAL_MES"]["_text"]
        };
      });
    } else {
      return [
        {
          name: moment(
            `${consumption["a:MES"]["_text"]} - ${(consumption["a:ANNO"][
              "_text"
            ],
            "M - YYYY")}`,
            "MYYYY"
          ).format("MMMM [de] YYYY"),
          value: parseFloat(consumption["a:TOTAL_MES"]["_text"])
        }
      ];
    }
  };
  const normalizeConsumptionDataTable = (
    ASEnergy = [],
    AEEnergy = [],
    R1Energy = [],
    R2Energy = [],
    R2PEnergy = []
  ) => {
    let result = [];
    let _months = [];
    try {
      if (Array.isArray(ASEnergy)) {
        _months = Array.from(Array(ASEnergy.length).keys());
        let as = ASEnergy[0];
        const getValue = field => {
          try {
            let value = field["a:TOTAL_MES"]
              ? parseFloat(field["a:TOTAL_MES"]["_text"])
              : 0;
            return value;
          } catch (err) {
            return 0;
          }
        };
        ASEnergy.forEach((item, index) => {
          console.log("->Item:  ", item);
          let r1 = R1Energy[index] || 0;
          let r2 = R2Energy[index] || 0;
          let r2p = R2PEnergy[index] || 0;
          let ae = AEEnergy[index] || 0;
          let el = {
            as: getValue(item),
            ae: getValue(ae),
            r1: getValue(r1),
            r2: getValue(r2),
            r2p: getValue(r2p)
          };

          let legend = `${moment(
            `${item["a:ANNO"]["_text"]}${item["a:MES"]["_text"]}`,
            "YYYYM"
          )
            .format("MMMM")
            .toUpperCase()} ${item["a:ANNO"]["_text"]}`;

          _months[index] = legend;
          /* if (months.indexOf(legend) === -1) months.push(legend); */
          result.push(el);
        });
        setMonths(_months);
        return result;
      }

      let date = moment(startSelectedDate);
      let legend = `${date.format("MMMM").toUpperCase()} ${date.format(
        "YYYY"
      )}`;

      _months = Array.from(Array(1).keys());
      _months[0] = legend;
      setMonths(_months);
      console.log("_> Current energy: ", ASEnergy);
      /*  months.push(
        `${moment(
          `${ASEnergy["a:ANNO"]["_text"]}${ASEnergy["a:MES"]["_text"]}`,
          "YYYYM"
        )
          .format("MMMM")
          .toUpperCase()} ${ASEnergy["a:ANNO"]["_text"]}`
      ); */
      /* setMonths([...months]); */
      /* for (let i = 0; i < 24; i++) {
        let hour = i + 1; */
      result.push({
        name: `${ASEnergy["a:ANNO"]["_text"]}-${ASEnergy["a:MES"]["_text"]}`,
        as: ASEnergy["a:TOTAL_MES"]
          ? parseFloat(ASEnergy["a:TOTAL_MES"]["_text"])
          : 0,
        ae: AEEnergy["a:TOTAL_MES"]
          ? parseFloat(AEEnergy["a:TOTAL_MES"]["_text"])
          : 0,
        r1: R1Energy["a:TOTAL_MES"]
          ? parseFloat(R1Energy["a:TOTAL_MES"]["_text"])
          : 0,
        r2: R2Energy["a:TOTAL_MES"]
          ? parseFloat(R2Energy["a:TOTAL_MES"]["_text"])
          : 0,
        r2p: R2PEnergy["a:TOTAL_MES"]
          ? parseFloat(R2PEnergy["a:TOTAL_MES"]["_text"])
          : 0
      });
      /*  } */
      //a:TOTAL_MES
      /* for (let i = 0; i < 24; i++) {
        let hour = i + 1;
        result.push({
          name: `${hour}:00`,
          as: ASEnergyItems[i] ? parseFloat(ASEnergyItems[i]["_text"]) : 0,
          ae: AEEnergyItems[i] ? parseFloat(AEEnergyItems[i]["_text"]) : 0,
          r1: R1EnergyItems[i] ? parseFloat(R1EnergyItems[i]["_text"]) : 0,
          r2: R2EnergyItems[i] ? parseFloat(R2EnergyItems[i]["_text"]) : 0,
          r2p: R2PEnergyItems[i] ? parseFloat(R2PEnergyItems[i]["_text"]) : 0
        });
      } */

      return result;
    } catch (err) {
      console.log("ERROR: ", err);
      return result;
    }
  };
  const fetchAllConsumption = async (origin, start_date, end_date) => {
    /* Set Months */
    /* setMonths([]); */
    let [
      consumptionAsResponse,
      consumptionAEResponse,
      consumptionR1Response,
      consumptionR2Response,
      consumptionR2PResponse
    ] = await Promise.all([
      fetchConsumption("AS", origin, start_date, end_date),
      fetchConsumption("AE", origin, start_date, end_date),
      fetchConsumption("R1", origin, start_date, end_date),
      fetchConsumption("R2", origin, start_date, end_date),
      fetchConsumption("R2P", origin, start_date, end_date)
    ]);
    let ASEnergy = getResultInConsumptionResponse(consumptionAsResponse),
      AEEnergy = getResultInConsumptionResponse(consumptionAEResponse),
      R1Energy = getResultInConsumptionResponse(consumptionR1Response),
      R2Energy = getResultInConsumptionResponse(consumptionR2Response),
      R2PEnergy = getResultInConsumptionResponse(consumptionR2PResponse);

    let normalizeData = normalizeConsumptionDataTable(
      ASEnergy,
      AEEnergy,
      R1Energy,
      R2Energy,
      R2PEnergy
    );
    console.log("--- TABLA ---");
    console.table(normalizeData);
    setConsumptionDataTable(normalizeData);
  };
  const fetchMonthlyConsumption = async (
    type,
    origin,
    start_date,
    end_date
  ) => {
    start_date = start_date != null ? start_date : startSelectedDate;
    start_date = moment(start_date).format("M");
    end_date = end_date != null ? end_date : endSelectedDate;
    end_date = moment(end_date).format("M");

    try {
      setLoading(true);
      setConsumptionData([]);
      let resp = await api.service("announcement-i").create({
        action: "GetConsumoMes",
        content: {
          "soapenv:Envelope": {
            _attributes: {
              "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
              "xmlns:tem": "http://tempuri.org/",
              "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
            },
            "soapenv:Header": {},
            "soapenv:Body": {
              "tem:GetConsumoMes": {
                _comment: [
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:"
                ],
                "tem:User": {
                  _comment: ["Optional:", "Optional:"],
                  "wcf:Password": {
                    _text: "2B09moR0"
                  },
                  "wcf:User": {
                    _text: "wstlmd"
                  }
                },
                "tem:NIC": {
                  _text: nic
                },
                "tem:ORIGEN": {
                  _text: origin
                },
                "tem:ENERGIA": {
                  _text: type.toUpperCase()
                },
                "tem:ANNO": {
                  _text: getYear(endSelectedDate)
                },
                "tem:MESI": {
                  _text: start_date
                },
                "tem:MESF": {
                  _text: end_date
                }
              }
            }
          }
        }
      });
      if (hasResultInConsumptionResponse(resp)) {
        let results = getResultInConsumptionResponse(resp);
        let normalize = normalizeConsumptionData(results);
        setConsumptionData(normalize);
      }
    } catch (e) {
      console.log("------> ERROR: ", e.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchConsumption = async (type, origin, start_date, end_date) => {
    start_date = start_date != null ? start_date : startSelectedDate;
    start_date = moment(start_date).format("M");
    end_date = end_date != null ? end_date : endSelectedDate;
    end_date = moment(end_date).format("M");

    return api.service("announcement-i").create({
      action: "GetConsumoMes",
      content: {
        "soapenv:Envelope": {
          _attributes: {
            "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
            "xmlns:tem": "http://tempuri.org/",
            "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
          },
          "soapenv:Header": {},
          "soapenv:Body": {
            "tem:GetConsumoMes": {
              _comment: [
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:"
              ],
              "tem:User": {
                _comment: ["Optional:", "Optional:"],
                "wcf:Password": {
                  _text: "2B09moR0"
                },
                "wcf:User": {
                  _text: "wstlmd"
                }
              },
              "tem:NIC": {
                _text: nic
              },
              "tem:ORIGEN": {
                _text: origin
              },
              "tem:ENERGIA": {
                _text: type
              },
              "tem:ANNO": {
                _text: getYear(endSelectedDate)
              },
              "tem:MESI": {
                _text: start_date
              },
              "tem:MESF": {
                _text: end_date
              }
            }
          }
        }
      }
    });
  };
  //TODO obtener el consumo mensual
  useEffect(() => {
    /* fetchAllConsumption(origin);
    fetchMonthlyConsumption(type, origin); */
    return () => {};
  }, [startSelectedDate, endSelectedDate, type, nic]);
  const componentRef = useRef();
  if (_origin !== origin) {
    fetchAllConsumption(origin);
    fetchMonthlyConsumption(type, origin);
    setOrigin(origin);
  }

  return (
    <BasePaper mb={6} loading={loading}>
      <Grid container ref={componentRef}>
        <Grid item component={Box} pb={4} xs={12}>
          <FiltersHeadline
            type={type}
            types={typeEnergy}
            title="Consumo mensual"
            onChangeType={handleTypeChange}
            firstDate={startSelectedDate}
            secondDate={endSelectedDate}
            onChangeFirstDate={date => {
              console.log("First Date:", moment(date).format("YYYYM"));
              startSelectedDate = date;
              setStartSelectedDate(date);
              fetchAllConsumption(origin, date);
              fetchMonthlyConsumption(type, origin, date);
              /* setMonths([]); */
              /* getMonths(date, endSelectedDate); */
            }}
            onChangeSecondDate={date => {
              console.log("Second Date:", date);
              setEndSelectedDate(date);
              fetchAllConsumption(origin, null, date);
              fetchMonthlyConsumption(type, origin, null, date);
              /* setMonths([]); */
              /* getMonths(endSelectedDate, date); */
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Box pl={5} mb={1}>
            <Typography color="primary" className={classes.title}>
              <Box fontWeight="fontWeightBold">Consulta</Box>
            </Typography>
          </Box>
          {/* <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
          /> */}

          <div className={classes.chart}>
            <ResponsiveContainer id="chart-01">
              <BarChart
                width={500}
                height={300}
                data={consumptionData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" name={`${getType(type)}`} fill="#FF925C" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item md={7} className={classes.content}>
          <Box mb={1}>
            <Typography color="primary" className={classes.title}>
              <Box fontWeight="fontWeightBold">Hojas de datos</Box>
            </Typography>
          </Box>
          <div>
            <ConsumptionTable
              isMonth
              months={months}
              id="cosume"
              table="hoja_datos"
              keys={typeEnergy.map(item => {
                item["value"] = item.value.toLowerCase();
                item["name"] = item.text;
                return item;
              })}
              filename="Hoja de datos - Consumo/Mes "
              consumptionData={consumptionDataTable}
            />
          </div>
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default MonthlyConsumption;
