import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import { Router, Link, Redirect } from "@reach/router";
import Login from "./views/Login";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Dashboard from "./components/Dashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import ConsumptionList from "./views/ConsumptionList";
import ConsumptionDetail from "./views/ConsumptionDetail";
import NoFound from "./views/NoFound";

import ProtectedLink from "./components/routes/ProtectedLink";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2F3687"
    },
    secondary: {
      main: "#F49226"
    },
    background: {
      default: "#fff"
    }
  },
  status: {
    danger: "orange"
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h2: {
      fontWeight: "bold",
      fontSize: "2.5rem",
      lineHeight: 1,
      letterSpacing: 0,
      color: "rgba(0,0,0,.65)"
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.30rem",
      lineHeight: 1,
      letterSpacing: 0,
      color: "rgba(0,0,0,.65)"
    },
    body1: {
      color: "rgba(0,0,0,.65)"
    }
  }
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Login path="/" />
        <ProtectedLink path="portal">
          <Dashboard path="dashboard">
            <ConsumptionList path="consumos" />
            <ConsumptionDetail path="consumo/:id" />
            <NoFound default />
          </Dashboard>
          <NoFound default />
        </ProtectedLink>
      </Router>
    </ThemeProvider>
  );
};

export default App;
