import React from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  FormControl,
  Input,
  useMediaQuery,
  Typography,
  Box
} from "@material-ui/core";
import { ErrorMessage, Field as FormikField } from "formik";

const useStylesField = makeStyles(theme => ({
  root: {
    border: "1px solid #2F3687",
    borderRadius: 25,
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff"
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: theme.palette.primary.main
    },
    "&::placeholder": {
      color: theme.palette.primary.main
    },
    padding: "0.1rem"
  },
  large: {
    padding: "18px 20px",
    borderRadius: 15
  },
  focused: {},
  input: {
    fontWeight: "lighter",
    paddingLeft: "1.0625rem",
    backgroundColor: "#fff",
    "&.MuiInputBase-input &.Mui-disabled": {
      opacity: 1,
      color: "red"
    }
  },
  error: {
    color: "red",
    display: "block",
    height: 20,
    padding: "4px 0",
    boxSizing: "content-box"
  }
}));

const AppInput = props => {
  return <Input {...props} {...props.field} />;
};

const AppTextField = ({
  className,
  large,
  hasFormik = false,
  name,
  label,
  ...props
}) => {
  const classes = useStylesField();

  const _renderLabel = () => {
    if (!label) {
      return;
    }
    return (
      <Typography>
        <Box mb={1} fontWeight="fontWeightBold">
          {label}
        </Box>
      </Typography>
    );
  };
  return (
    <React.Fragment>
      {_renderLabel()}
      <FormControl
        variant="outlined"
        className={clsx([classes.root, large && classes.large, className])}
      >
        {hasFormik ? (
          <FormikField
            disableUnderline
            component={AppInput}
            className={classes.input}
            name={name}
            {...props}
          />
        ) : (
          <Input disableUnderline className={classes.input} {...props} />
        )}
      </FormControl>
      {hasFormik && (
        <span className={clsx(classes.error)}>
          <ErrorMessage name={name} />
        </span>
      )}
    </React.Fragment>
  );
};

export default AppTextField;
