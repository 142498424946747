import dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand

dayjs.locale('es'); // use Spanish locale globally

export const getCurrentDate = () => dayjs();

export const subtractDays = (date, days) => dayjs(date).subtract(days, 'day');
export const subtractMonth = (date, months) =>
  dayjs(date).subtract(months, 'month');

export const formatDate = date => dayjs(date).format('YYYY-MM-DD');

export const getYear = date => dayjs(date).year();

export const getMonth = date => dayjs(date).month();

export const formatMonth = month =>
  dayjs()
    .month(month)
    .format('MMM');

export const formatMonthAndDay = date => dayjs(date).format('');
