import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import BasePaper from "./BasePaper";
import { unityEnergy, energyType } from "../../constants/energy";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import DatePicker from "../DatePicker";
import AppSelect from "../AppSelect";
import FiltersHeadline from "./FiltersHeadline";
import api from "../../services/api";
import { formatDate, getCurrentDate, subtractDays } from "../../modules/date";
import { print } from "../../utils/Helper";
import { typeEnergy } from "../../constants/energy";
import { moment } from "../../utils/Helper";

const useStyles = makeStyles(theme => ({
  options: {
    width: "80%"
  },
  chart: {
    height: 300
  }
}));

const DailyConsumption = ({ nic, origin = "1" }) => {
  const classes = useStyles();

  let currentDate = getCurrentDate();
  const [_origin, setOrigin] = useState();
  const [startSelectedDate, setStartSelectedDate] = useState(
    moment().subtract(1, "days")
  );
  const [endSelectedDate, setEndSelectedDate] = useState(moment());

  const [loading, setLoading] = useState(false);
  let [label, setLabel] = useState("Consumo (kWH)");

  let [type, setType] = useState(typeEnergy[0].value.toLowerCase());

  const [consumptionData, setConsumptionData] = useState([]);

  const handleTypeChange = e => {
    let { value } = e.target;
    type = value;
    setType(value);
    label = `Consumo (${unityEnergy[value.toUpperCase()]})`;
    setLabel(`Consumo (${unityEnergy[value.toUpperCase()]})`);
    fetchDailyConsumption(origin, type);
  };

  const hasResultInConsumptionResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetConsumoDiaResponse"][
        "GetConsumoDiaResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };

  const getResultInConsumptionResponse = response => {
    return response["s:Envelope"]["s:Body"]["GetConsumoDiaResponse"][
      "GetConsumoDiaResult"
    ]["a:Consumos"]["a:ConsumoDia"];
  };

  const normalizeConsumptionData = (consumption = []) => {
    if (!Array.isArray(consumption))
      return {
        name: formatDate(consumption["a:FECHA"]["_text"]),
        value: parseFloat(consumption["a:TOTAL_DIA"]["_text"])
      };
    return consumption.map(item => ({
      name: formatDate(item["a:FECHA"]["_text"]),
      value: parseFloat(item["a:TOTAL_DIA"]["_text"])
    }));
  };

  const fetchDailyConsumption = async (origin, type, start_date, end_date) => {
    start_date = start_date != null ? start_date : startSelectedDate;
    start_date = start_date.format("YYYY-MM-DD");
    end_date = end_date != null ? end_date : endSelectedDate;
    end_date = end_date.format("YYYY-MM-DD");

    try {
      setLoading(true);
      let resp = await api.service("announcement-i").create({
        action: "GetConsumoDia",
        content: {
          "soapenv:Envelope": {
            _attributes: {
              "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
              "xmlns:tem": "http://tempuri.org/",
              "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
            },
            "soapenv:Header": {},
            "soapenv:Body": {
              "tem:GetConsumoDia": {
                _comment: [
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:"
                ],
                "tem:User": {
                  _comment: ["Optional:", "Optional:"],
                  "wcf:Password": {
                    _text: "2B09moR0"
                  },
                  "wcf:User": {
                    _text: "wstlmd"
                  }
                },
                "tem:NIC": {
                  _text: nic
                },
                "tem:ORIGEN": {
                  _text: origin
                },
                "tem:ENERGIA": {
                  _text: type.toUpperCase() // AS
                },
                "tem:FECHAI": {
                  _text: start_date
                },
                "tem:FECHAF": {
                  _text: end_date
                }
              }
            }
          }
        }
      });
      if (hasResultInConsumptionResponse(resp)) {
        let results = getResultInConsumptionResponse(resp);
        setConsumptionData(normalizeConsumptionData(results));
      }
    } catch (e) {
      console.log("> ERROR: ", e.message);
    } finally {
      setLoading(false);
    }
  };
  if (_origin !== origin) {
    fetchDailyConsumption(origin, type);
    setOrigin(origin);
  }
  useEffect(() => {
    /* fetchDailyConsumption(origin); */
    //soap();
    return () => {};
  }, [startSelectedDate, endSelectedDate, type, nic]);

  const PrintGraphic = id => {
    print(id);
  };
  const getType = type => {
    let energyType = typeEnergy.filter(item => item.value === type);
    if (energyType.length > 0) return energyType[0].text;
    return "";
  };
  return (
    <BasePaper className={classes.root} loading={loading}>
      <FiltersHeadline
        type={type}
        types={typeEnergy}
        title="Consumo diario"
        onChangeType={handleTypeChange}
        firstDate={startSelectedDate}
        secondDate={endSelectedDate}
        onChangeFirstDate={date => {
          date = moment(date);
          setStartSelectedDate(date);
          fetchDailyConsumption(origin, type, date);
        }}
        onChangeSecondDate={date => {
          date = moment(date);
          setEndSelectedDate(date);
          fetchDailyConsumption(origin, type, null, date);
        }}
      />
      <div className={classes.chart}>
        <ResponsiveContainer>
          <AreaChart
            width={500}
            height={400}
            data={consumptionData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Legend />
            <Tooltip />
            <Area
              type="monotone"
              name={`${getType(type)}`}
              dataKey="value"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {/* <button onClick={() => PrintGraphic("chart")}>Imprimir</button> */}
    </BasePaper>
  );
};

export default DailyConsumption;
