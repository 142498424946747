import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {Input, makeStyles} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  input: {
    cursor: 'pointer',
    maxWidth: 115,
    color: 'rgba(0, 0, 0, 0.67)',
  },
}));

const DatePicker = ({selectedDate, handleDateChange}) => {
  const classes = useStyles();
  const _renderInput = ({InputProps, ...other}) => {
    return (
      <Input
        type="text"
        onClick={InputProps.endAdornment.props.children.props.onClick}
        disableUnderline
        className={classes.input}
        endAdornment={
          <InputAdornment position="end">
            <KeyboardArrowDownIcon />
          </InputAdornment>
        }
        {...other}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        format="MM/dd/yyyy"
        id="consumption-picker-inline"
        value={selectedDate}
        TextFieldComponent={_renderInput}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
