import React from 'react';
import {TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
}));

const AppTableRow = ({children}) => {
  const classes = useStyles();

  return <TableRow className={classes.row}>{children}</TableRow>;
};

export default AppTableRow;
