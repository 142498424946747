import React from "react";
import { makeStyles, Paper, Table, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: "hidden",
    "& th, & td": {
      border: "0.3px solid rgba(0, 0, 0, 0.10)",
      color: "rgba(0,0,0,.65)"
    }
  },
  root: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "scroll",
    paddingBottom: theme.spacing(1)
  }
}));

const AppTable = ({ children, loading, ...props }) => {
  const classes = useStyles();

  return (
    <div className={props.className || classes.root}>
      {loading && <LinearProgress />}
      <Table className={classes.table} {...props}>
        {children}
      </Table>
    </div>
  );
};

export default AppTable;
