import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./exportexcel.css";
import { Icon } from "antd";
import { Box, Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 2,
    position: "relative"
  },
  title: {
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  back: {
    /* background: "#ccc", */
    fontSize: 14,
    borderRadius: 8,
    color: "rgba(0, 0, 0, 0.87)"
  },
  input: {
    display: "none"
  }
}));

const ExportToExcel = props => {
  const classes = useStyles();
  let {
    id = "table-to-excel",
    table,
    filename = "table",
    label = "Descargar Excel",
    sheet = "Reporte"
  } = props;
  if (!table) return null;
  return (
    <div className="export-to-excel-button">
      <label className="label" htmlFor={id}>
        <IconButton
          color="primary"
          className={classes.back}
          aria-label="upload picture"
          component="span"
        >
          <Icon type="file-excel" />
          <Box className={classes.title} fontWeight="fontWeightBold" pl={1}>
            <span>{label}</span>
          </Box>
        </IconButton>
      </label>
      <ReactHTMLTableToExcel
        id={id}
        className="download-table-xls-button"
        table={table}
        filename={filename}
        sheet={sheet}
        buttonText={label}
      />
    </div>
  );
};
export default ExportToExcel;
