import React, { useEffect, useState, useMemo } from "react";
import debounce from "lodash/debounce";
import {
  Container,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Box,
  IconButton,
  Link,
  Button,
  SnackbarContent
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AppTextField from "../components/AppTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import StatisticsIcon from "../components/StatictisIcon";
import DownloadIcon from "../components/DownloadIcon";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import TableCellHead from "../components/table/TableCellHead";
import AppTable from "../components/table/AppTable";
import { navigate } from "@reach/router";
import api from "../services/api";
import { decimal } from "../utils/Helper";

/* Select Dependences */
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
/* const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1)
  }
})); */
const origin_types = [
  {
    text: "Telemedidos",
    value: "1"
  },
  {
    text: "Facturados (AENC)",
    value: "2"
  }
];
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: "hidden",
    "& th, & td": {
      border: "0.3px solid rgba(0, 0, 0, 0.10)"
    }
  },
  tableHead: {
    background: "#2F368715 0% 0% no-repeat padding-box",
    color: "rgba(0,0,0,0.6)",
    letterSpacing: 0
  },
  tableHeadItem: {
    fontWeight: "bold",
    padding: "1.5rem 1.3375rem",
    fontSize: "0.8rem"
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  headline: {
    padding: "8px",
    margin: 10
  },
  searchIcon: {
    padding: "0.2rem",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: "#fff",
    boxSizing: "content-box"
  },
  field: {
    width: "100%",
    maxWidth: "535px"
  },
  tableCellOption: {
    background: "#F4922619 0% 0% no-repeat padding-box",
    padding: "0 10px"
  },
  roundButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  largeHead: {
    minWidth: 200
  },
  /* Actales */
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1)
  }
}));
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #2F3687",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#2F3687",
      boxShadow: "0 0 0 0.2rem #2F368725"
    }
  }
}))(InputBase);
const useLoadingCellStyle = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    display: "block"
  }
}));

const LoadingCell = ({ children, loading }) => {
  const classes = useLoadingCellStyle();
  if (loading) {
    return (
      <Skeleton
        variant="rect"
        className={classes.root}
        width="80%"
        height={20}
      />
    );
  }
  return children;
};

const SelectOption = props => {
  const classes = useStyles();
  let { label } = props;
  return (
    <>
      {label && (
        <InputLabel htmlFor={props.id || "select-simple"}>{label}</InputLabel>
      )}
      <Select
        native
        value={props.value}
        onChange={props.onChange}
        input={<BootstrapInput name="age" id="age-customized-select" />}
        inputProps={{
          name: props.name,
          id: props.id || "select-simple"
        }}
      >
        {props.dataSource.map(option => {
          let valueField = props["valueField"] || "value";
          let displayField = props["displayField"] || "text";
          return (
            <option key={option[valueField]} value={option[valueField]}>
              {option[displayField]}
            </option>
          );
        })}
      </Select>
    </>
  );
};
const ConsumptionList = props => {
  const classes = useStyles();
  const [nics, setNics] = useState({ data: [] });
  const [loading, setLoading] = useState(true);
  const [loadingNicInformation, setLoadingNicInformation] = useState(true);
  const [searchNic, setSearchNic] = useState("");
  const [origin_type, setOriginType] = useState("1");
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({ limit: 0, skip: 0, total: 0 });
  const [error, setError] = useState(false);

  const handleChange = event => {
    let { value } = event.target;
    setOriginType(value);
    fetchUserNit(value);
  };

  const fetchUserNit = async (orgin_type = "1", nic) => {
    try {
      setError(false);
      setLoading(true);
      const resp = await api.service("users-nic").find({
        query: {
          $limit: 4,
          nic,
          $skip: (page - 1 > 0 ? page - 1 : 0) * 4
        }
      });
      setPagination({ limit: resp.limit, skip: resp.skip, total: resp.total });
      setNics({
        limit: resp.limit,
        skip: resp.skip,
        total: resp.total,
        data: resp.data.map(item => ({
          ...item,
          address: "",
          r1: "",
          r2: "",
          r2p: "",
          as: "",
          loading: true
        }))
      });
      await fetchNicInformation(resp.data, orgin_type);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchNicInformation = async (nics = [], orgin_type) => {
    try {
      let requests = nics.map(item =>
        fetchCurrentConsumption(item.nic, orgin_type)
      );
      const resp = await Promise.all(requests);
      setNicInformation(resp, nics, orgin_type);
    } catch (e) {
      throw e.message;
    }
  };

  const hasResultInFetchInformationResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetConsumoActResponse"][
        "GetConsumoActResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };

  const getResultInNicInformationResponse = response => {
    return response["s:Envelope"]["s:Body"]["GetConsumoActResponse"][
      "GetConsumoActResult"
    ]["a:Consumos"]["a:ConsumoAct"];
  };

  const getConsumptionWithTypeEnergy = (consumptions, typeEnergy) => {
    let result = consumptions.find(
      consumption => consumption["a:TIPO_ENERGIA"]["_text"] === typeEnergy
    );
    return result ? result["a:CONSUMO"]["_text"] : 0;
  };

  const setNicInformation = (nicInformationResponse, nicItems, orgin_type) => {
    let results;

    let nicInformation = nicInformationResponse.map(response => {
      if (hasResultInFetchInformationResponse(response)) {
        let data = getResultInNicInformationResponse(response);
        return {
          nic: data[0]["a:COD_NIC"]["_text"],
          address: data[0]["a:DIRECCION"]["_text"],
          r1: getConsumptionWithTypeEnergy(data, "R1"),
          r2: getConsumptionWithTypeEnergy(data, "R2"),
          r2p: getConsumptionWithTypeEnergy(data, "R1P"),
          as: getConsumptionWithTypeEnergy(data, "AS")
        };
      }
      return {};
    });

    results = nicItems.map(nic => {
      let currentNicInformation = nicInformation.find(
        item => item.nic === nic.nic
      );

      if (currentNicInformation) {
        delete currentNicInformation.nic;
        return { ...nic, ...currentNicInformation };
      }

      return nic;
    });
    let _nics = [];
    results = results.filter(item => {
      if (_nics.indexOf(item.nic) == -1) {
        _nics.push(item.nic);
        return true;
      }
      return false;
    });
    setNics({
      data: results
    });
    setLoadingNicInformation(false);
  };

  const fetchCurrentConsumption = (nic, orgin_type = "1") => {
    return api.service("announcement-i").create({
      action: "GetConsumoAct",
      content: {
        "soapenv:Envelope": {
          _attributes: {
            "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
            "xmlns:tem": "http://tempuri.org/",
            "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
          },
          "soapenv:Header": {},
          "soapenv:Body": {
            "tem:GetConsumoAct": {
              _comment: ["Optional:", "Optional:", "Optional:"],
              "tem:User": {
                _comment: ["Optional:", "Optional:"],
                "wcf:Password": {
                  _text: "2B09moR0"
                },
                "wcf:User": {
                  _text: "wstlmd"
                }
              },
              "tem:NIC": {
                _text: nic
              },
              "tem:ORIGEN": {
                _text: orgin_type
              }
            }
          }
        }
      }
    });
  };

  const handleNext = () => {
    let current = page;
    let total = Math.ceil(pagination.total / 4) || 1;
    if (page < total) {
      setPage(current + 1);
    }
  };

  const handlePrev = () => {
    let current = page;
    if (page > 1) {
      setPage(current - 1);
    }
  };
  let handleSearch = e => {
    let { value } = e.target;
    if (!value) return fetchUserNit(origin_type);
    fetchUserNit(origin_type, value);
  };

  useEffect(() => {
    fetchUserNit(origin_type);
    handleSearch = debounce(handleSearch, 300);
    return () => {};
  }, [page]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper elevation={0} className={classes.root}>
          <Grid
            className={classes.headline}
            container
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2" className={classes.title}>
                Portal Telemedidos
              </Typography>
              {/* <Typography variant="subtitle1">
                Consumo neto por sucursal en el mes de Septiembre de 2019
              </Typography> */}
            </Grid>
            {
              <Grid item xs={12} md={4}>
                <Box mt={{ xs: 3 }}>
                  <AppTextField
                    placeholder="Búsqueda por Nic"
                    className={classes.field}
                    onChange={handleSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    }
                  />
                </Box>
              </Grid>
            }
          </Grid>
          {error && (
            <Box mb={4}>
              <SnackbarContent
                className={classes.snackbar}
                message="Ups, ocurrio un error al cargar la informacion..."
                action={
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => fetchUserNit()}
                  >
                    <Box fontWeight="fontWeightBold">Reintentar</Box>
                  </Button>
                }
              />
            </Box>
          )}
          <Grid
            className={classes.headline}
            container
            /*  alignItems="center" */
            justify="space-between"
          >
            <Grid
              item
              span={24}
              style={{
                margin: 10,
                padding: 10
              }}
            >
              <SelectOption
                /* label="Origen de Datos" */
                dataSource={origin_types}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <AppTable loading={loading}>
            <TableHead>
              <TableRow>
                <TableCellHead align="left">Nic</TableCellHead>
                <TableCellHead className={classes.largeHead}>
                  Dirección
                </TableCellHead>
                <TableCellHead>
                  Energía <br /> activa de salida (kWh)
                </TableCellHead>
                {/* <TableCellHead>
                  Energía <br /> activa de entrada
                </TableCellHead> */}
                <TableCellHead>
                  Energía <br /> Energía reactiva capacitiva (kVArh)
                </TableCellHead>
                <TableCellHead>
                  Energía <br /> reactiva inductiva (kVArh)
                </TableCellHead>
                <TableCellHead>
                  Energía <br /> reactiva penalizada (kVArh)
                </TableCellHead>

                <TableCellHead>
                  Ver detalles <br /> de consumo
                </TableCellHead>
                {/* <TableCellHead>
                  Descargar <br /> Factura
                </TableCellHead> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {nics.data.map(item => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    <Box fontWeight="fontWeightBold">{item.nic}</Box>
                  </TableCell>
                  <TableCell align="right">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {item.address || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell>
                  <TableCell align="center">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {decimal(item.as) || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell>
                  {/* <TableCell align="center">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {item.ae || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell> */}
                  <TableCell align="center">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {decimal(item.r1) || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell>
                  <TableCell align="center">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {decimal(item.r2) || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell>
                  <TableCell align="center">
                    <LoadingCell loading={loadingNicInformation}>
                      <Box fontWeight="fontWeightBold">
                        {decimal(item.r2p) || "No se encontro informacion."}
                      </Box>
                    </LoadingCell>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellOption}>
                    <Box
                      display="flex"
                      my={1}
                      alignItems="center"
                      flexDirection="column"
                    >
                      <IconButton
                        onClick={() => {
                          localStorage.setItem("nic", JSON.stringify(item));
                          navigate(`/portal/dashboard/consumo/${item.nic}`);
                        }}
                      >
                        <StatisticsIcon />
                      </IconButton>
                      <Link
                        onClick={() =>
                          navigate(`/portal/dashboard/consumo/${item.nic}`)
                        }
                      >
                        Origen de datos
                      </Link>
                    </Box>
                  </TableCell>
                  {/* <TableCell align="center" className={classes.tableCellOption}>
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </AppTable>
          <Box mt={8}>
            <Grid container justify="flex-end" className={classes.actions}>
              <Grid item>
                <Box mx={4} my={{ xs: 1 }}>
                  <Typography>Resultados: {page}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mx={4} my={{ xs: 1 }}>
                  <Typography>Nº registros: 4</Typography>
                </Box>
              </Grid>
              <Grid
                component={Box}
                /* alignItems="center" */ display="flex"
                item
              >
                <IconButton
                  className={classes.roundButton}
                  aria-label="more"
                  onClick={handlePrev}
                  size="small"
                >
                  <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <Box mx={1} my={{ xs: 1 }} clone>
                  <span>
                    Página {page} de {Math.ceil(pagination.total / 4) || 1}
                  </span>
                </Box>
                <IconButton
                  className={classes.roundButton}
                  aria-label="more"
                  onClick={handleNext}
                  size="small"
                >
                  <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default ConsumptionList;
