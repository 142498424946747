import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LockIcon = props => {
  return (
    <SvgIcon
      width="25.223"
      height="29.653"
      viewBox={null}
      style={{width: 'auto', height: 'auto'}}
      {...props}>
      <defs>
        <style>{'.prefix__a{fill:rgba(0,0,0,.45)}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M10.315 21.912l-.413 3.742a.66.66 0 00.656.733h2.639a.66.66 0 00.656-.733l-.416-3.742a2.61 2.61 0 001.08-2.122 2.639 2.639 0 10-5.277 0 2.61 2.61 0 001.075 2.122zm1.559-3.441a1.315 1.315 0 01.569 2.5.66.66 0 00-.368.667l.381 3.428h-1.165l.381-3.428a.66.66 0 00-.368-.667 1.315 1.315 0 01.569-2.5z"
      />
      <path
        className="prefix__a"
        d="M23.088 11.874h-1.979V9.235a9.236 9.236 0 10-18.471 0v2.639H.66a.659.659 0 00-.66.66v16.491a2.641 2.641 0 002.639 2.639h18.47a2.641 2.641 0 002.639-2.639V12.534a.659.659 0 00-.66-.66zM3.958 9.235a7.916 7.916 0 1115.832 0v2.639h-1.319V9.235a6.6 6.6 0 10-13.193 0v2.639h-1.32zm13.193 0v2.639H6.6V9.235a5.278 5.278 0 0110.555 0zm5.277 19.79a1.321 1.321 0 01-1.319 1.319H2.639a1.321 1.321 0 01-1.319-1.319V13.193h21.109z"
      />
    </SvgIcon>
  );
};

export default LockIcon;
