import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import {useTheme} from '@material-ui/core/styles';

const AvatarIcon = ({color = 'primary', props}) => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="25.223"
      height="29.653"
      viewBox={null}
      style={{width: 'auto', height: 'auto'}}
      {...props}>
      <path
        style={{fill: theme.palette[color].main}}
        d="M12.504 15.978h.2a5.666 5.666 0 004.329-1.873c2.364-2.665 1.971-7.234 1.928-7.67-.154-3.273-1.7-4.839-2.978-5.57a6.827 6.827 0 00-3.3-.866h-.1a6.838 6.838 0 00-3.3.841c-1.29.731-2.862 2.3-3.015 5.594-.043.436-.436 5 1.928 7.67a5.644 5.644 0 004.308 1.874zM7.892 6.589c0-.018.006-.037.006-.049.2-4.4 3.328-4.876 4.667-4.876h.074c1.658.037 4.477.712 4.667 4.876a.12.12 0 00.006.049c.006.043.436 4.219-1.517 6.417a4.025 4.025 0 01-3.162 1.314h-.061a4.012 4.012 0 01-3.156-1.314c-1.947-2.186-1.53-6.38-1.524-6.417z"
      />
      <path
        style={{fill: theme.palette[color].main}}
        d="M25.221 23.555v-.018c0-.049-.006-.1-.006-.154-.037-1.216-.117-4.059-2.782-4.968l-.061-.018a17.719 17.719 0 01-5.1-2.321.829.829 0 10-.952 1.357 19.124 19.124 0 005.607 2.561c1.431.51 1.59 2.039 1.633 3.439a1.232 1.232 0 00.006.154 11.147 11.147 0 01-.129 1.9 22.6 22.6 0 01-10.826 2.518 22.728 22.728 0 01-10.829-2.53 10.552 10.552 0 01-.129-1.9c0-.049.006-.1.006-.154.043-1.4.2-2.929 1.633-3.439a19.3 19.3 0 005.606-2.561.829.829 0 00-.952-1.357 17.523 17.523 0 01-5.1 2.321c-.025.006-.043.012-.061.018C.12 19.318.04 22.161.003 23.371a1.233 1.233 0 01-.006.154v.018a9.416 9.416 0 00.313 2.782.788.788 0 00.319.387c.184.123 4.6 2.935 11.986 2.935s11.8-2.819 11.986-2.935a.822.822 0 00.319-.387 9.879 9.879 0 00.301-2.77z"
      />
    </SvgIcon>
  );
};

export default AvatarIcon;
