import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import AppSelect from '../AppSelect';
import DatePicker from '../DatePicker';

const useStyles = makeStyles(theme => ({}));

const FiltersHeadline = ({
  title,
  onChangeType,
  onChangeFirstDate,
  firstDate,
  onChangeSecondDate,
  type,
  types,
  secondDate,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Typography>
        <Box fontWeight="fontWeightBold">{title}</Box>
      </Typography>
      <Grid container justify="center" component={Box} mb={1}>
        <Grid item>
          <Box mx={1}>
            <AppSelect value={type} onChange={onChangeType} items={types} />
          </Box>
        </Grid>
        <Grid item>
          <Box mx={1}>
            <DatePicker
              selectedDate={firstDate}
              handleDateChange={onChangeFirstDate}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mx={1}>
            <DatePicker
              selectedDate={secondDate}
              handleDateChange={onChangeSecondDate}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FiltersHeadline;
