import React from 'react';
import {Box, makeStyles, Paper, CircularProgress} from '@material-ui/core';
import clsx from 'clsx';

import NetworkError from '../NetworkError';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 20,
    boxShadow: '0px 7px 69px -24px rgba(47,54,135,0.5)',
    position: 'relative',
    overflow: 'hidden',
  },
  back: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: -20,
    marginRight: -20,
    zIndex: 2,
  },
}));

const BasePaper = ({
  children,
  className,
  loading,
  error,
  onRefresh,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Paper
      component={Box}
      py={3}
      px={4}
      className={clsx(classes.paper, className)}
      {...props}>
      {error && <NetworkError onRefresh={onRefresh} />}
      {loading && (
        <React.Fragment>
          <div className={classes.back} />
          <CircularProgress className={classes.progress} />
        </React.Fragment>
      )}
      {children}
    </Paper>
  );
};

export default BasePaper;
