import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer
} from "recharts";
import { moment } from "../../utils/Helper";
import DatePicker from "../DatePicker";
import BasePaper from "./BasePaper";
import api from "../../services/api";
import { getCurrentDate, formatDate } from "../../modules/date";
import ConsumptionTable from "../ConsumptionTable";
const useStyles = makeStyles(theme => ({
  content: {
    width: "100%"
  },
  chart: {
    height: 300,
    width: "100%"
  }
}));

const ConsumptionPerHour = ({ nic, origin = "1" }) => {
  const classes = useStyles();
  let currentDate = getCurrentDate();

  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "days")
  );
  const [_origin, setOrigin] = useState();

  const [consumptionData, setConsumptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  /* const [origin, setOrigin] = useState(String(origin)); */

  const fetchConsumption = (type, origin, fecha) => {
    fecha = fecha || selectedDate;

    fecha = fecha.format("YYYY-MM-DD");

    console.log("FECHA >", fecha);
    return api.service("announcement-i").create({
      action: "GetConsumoHora",
      content: {
        "soapenv:Envelope": {
          _attributes: {
            "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
            "xmlns:tem": "http://tempuri.org/",
            "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
          },
          "soapenv:Header": {},
          "soapenv:Body": {
            "tem:GetConsumoHora": {
              _comment: [
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:"
              ],
              "tem:User": {
                _comment: ["Optional:", "Optional:"],
                "wcf:Password": {
                  _text: "2B09moR0"
                },
                "wcf:User": {
                  _text: "wstlmd"
                }
              },
              "tem:NIC": {
                _text: nic
              },
              "tem:ORIGEN": {
                _text: origin
              },
              "tem:ENERGIA": {
                _text: type
              },
              "tem:FECHAI": {
                _text: fecha
              },
              "tem:FECHAF": {
                _text: fecha
              }
            }
          }
        }
      }
    });
  };

  const normalizeConsumptionData = (
    ASEnergy,
    AEEnergy,
    R1Energy,
    R2Energy,
    R2PEnergy
  ) => {
    let ASEnergyItems = Object.values(ASEnergy),
      AEEnergyItems = Object.values(AEEnergy),
      R1EnergyItems = Object.values(R1Energy),
      R2EnergyItems = Object.values(R2Energy),
      R2PEnergyItems = Object.values(R2PEnergy),
      result = [];

    Object.keys(ASEnergy).forEach((element, index) => {
      let hour = index + 1;

      let as = ASEnergy[`a:VALOR${hour}`];
      let ae = AEEnergy[`a:VALOR${hour}`];
      let r1 = R1Energy[`a:VALOR${hour}`];
      let r2 = R2Energy[`a:VALOR${hour}`];
      let r2p = R2PEnergy[`a:VALOR${hour}`];
      result.push({
        name: `${hour}:00`,
        as: as ? parseFloat(as["_text"]) : 0,
        ae: ae ? parseFloat(ae["_text"]) : 0,
        r1: r1 ? parseFloat(r1["_text"]) : 0,
        r2: r2 ? parseFloat(r2["_text"]) : 0,
        r2p: r2p ? parseFloat(r2p["_text"]) : 0
      });
    });

    /* for (let i = 0; i < 24; i++) {
      let hour = i + 1;
      result.push({
        name: `${hour}:00`,
        as: ASEnergyItems[i] ? parseFloat(ASEnergyItems[i]["_text"]) : 0,
        ae: AEEnergyItems[i] ? parseFloat(AEEnergyItems[i]["_text"]) : 0,
        r1: R1EnergyItems[i] ? parseFloat(R1EnergyItems[i]["_text"]) : 0,
        r2: R2EnergyItems[i] ? parseFloat(R2EnergyItems[i]["_text"]) : 0,
        r2p: R2PEnergyItems[i] ? parseFloat(R2PEnergyItems[i]["_text"]) : 0
      });
    } */

    return result;
  };

  const hasResultInConsumptionResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetConsumoHoraResponse"][
        "GetConsumoHoraResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };

  const getResultInConsumptionResponse = response => {
    if (!hasResultInConsumptionResponse(response)) {
      return {};
    }
    let result =
      response["s:Envelope"]["s:Body"]["GetConsumoHoraResponse"][
        "GetConsumoHoraResult"
      ]["a:Consumos"]["a:MatrizConsumo"];

    delete result["a:COD_NIC"];
    delete result["a:FECHA"];
    delete result["a:ORIGEN"];
    delete result["a:TIPO_ENERGIA"];

    return result;
  };

  const fetchAllConsumption = async (origin, fecha) => {
    fecha = fecha || selectedDate;
    try {
      console.time("fetchAllConsumption");
      setLoading(true);
      setError(false);
      let [
        consumptionAsResponse,
        consumptionAEResponse,
        consumptionR1Response,
        consumptionR2Response,
        consumptionR2PResponse
      ] = await Promise.all([
        fetchConsumption("AS", origin, fecha),
        fetchConsumption("AE", origin, fecha),
        fetchConsumption("R1", origin, fecha),
        fetchConsumption("R2", origin, fecha),
        fetchConsumption("R2P", origin, fecha)
      ]);

      let ASEnergy = getResultInConsumptionResponse(consumptionAsResponse),
        AEEnergy = getResultInConsumptionResponse(consumptionAEResponse),
        R1Energy = getResultInConsumptionResponse(consumptionR1Response),
        R2Energy = getResultInConsumptionResponse(consumptionR2Response),
        R2PEnergy = getResultInConsumptionResponse(consumptionR2PResponse);

      let normalizeData = normalizeConsumptionData(
        ASEnergy,
        AEEnergy,
        R1Energy,
        R2Energy,
        R2PEnergy
      );

      setConsumptionData(normalizeData);

      /*  console.table(normalizeData); */
      console.timeEnd("fetchAllConsumption");
    } catch (e) {
      setError(true);
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (_origin !== origin) {
    try {
      fetchAllConsumption(origin);
    } catch (e) {
      alert(e.message);
    }
    setOrigin(origin);
  }
  useEffect(() => {
    /* try {
      fetchAllConsumption(origin);
    } catch (e) {
      alert(e.message);
    } */
    return () => {};
  }, [selectedDate]);

  const handleOnRefresh = () => {
    fetchAllConsumption(origin);
  };

  const handleDateChange = date => {
    let fecha = moment(date);
    console.log("FECHA: ", fecha);
    setSelectedDate(fecha);
    fetchAllConsumption(origin, fecha);
  };

  return (
    <BasePaper loading={loading} error={error} onRefresh={handleOnRefresh}>
      <Grid container>
        <Grid
          item
          component={Box}
          display="flex"
          direction="column"
          alignItems="center"
          pb={4}
          xs={12}
          justify="center"
        >
          <Typography>
            <Box fontWeight="fontWeightBold">Consumo / Hora</Box>
          </Typography>
          <Box alignItems="center" display="flex">
            <Typography>
              <Box mr={1}>Fecha:</Box>
            </Typography>
            <DatePicker
              formatDate="YYYY-MM-DD"
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box pl={5} mb={1}>
            <Typography color="primary" className={classes.title}>
              <Box fontWeight="fontWeightBold">Consulta</Box>
            </Typography>
          </Box>
          <div className={classes.chart}>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={consumptionData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />

                <Line
                  type="monotone"
                  dataKey="as"
                  stroke="#8E8CD7"
                  name="Energía activa de salida (kWh)"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  name="Energía activa de entrada (kWh)"
                  dataKey="ae"
                  stroke="#A4D7B7"
                />
                <Line
                  type="monotone"
                  name="Energía reactiva capacitiva (kVArh)"
                  dataKey="r1"
                  stroke="#FF925C"
                />
                <Line
                  type="monotone"
                  name="Energía reactiva inductiva (kVArh)"
                  dataKey="r2"
                  stroke="#FF925C"
                />
                <Line
                  type="monotone"
                  name="Energía reactiva inductiva penalizada (kVArh)"
                  dataKey="r2p"
                  stroke="#FF925C"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        <Grid item md={7} className={classes.content}>
          <Box mb={1}>
            <Typography color="primary" className={classes.title}>
              <Box fontWeight="fontWeightBold">Hojas de datos</Box>
            </Typography>
          </Box>
          <ConsumptionTable
            id="cosume_hours"
            table="hoja_dato_hours"
            filename="Hoja de datos - Consumo/Hora"
            keys={[
              { value: "as", name: "Energía activa de salida (kWh)" },
              { value: "ae", name: "Energía activa de entrada (kWh)" },
              { value: "r1", name: "Energía reactiva capacitiva (kVArh)" },
              { value: "r2", name: "Energía reactiva inductiva (kVArh)" },
              {
                value: "r2p",
                name: "Energía reactiva inductiva penalizada (kVArh)"
              }
            ]}
            consumptionData={consumptionData}
          />
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default ConsumptionPerHour;
