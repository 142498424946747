import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StatisticsIcon = props => {
  return (
    <SvgIcon
      width={33.707}
      height={33.707}
      viewBox={null}
      style={{width: 'auto', height: 'auto'}}
      {...props}>
      <defs>
        <style>{'.prefix__a{fill:#f49226}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M33.169 30.48H3.227V.538a.538.538 0 10-1.076 0V5.08H.538a.538.538 0 000 1.076h1.614v4H.538a.538.538 0 000 1.076h1.614v4H.538a.538.538 0 100 1.076h1.614v4H.538a.538.538 0 100 1.076h1.614v4H.538a.538.538 0 100 1.076h1.614v4.542a.538.538 0 00.538.538h7.949v1.614a.538.538 0 101.076 0v-1.598h12.43v1.614a.538.538 0 101.076 0v-1.614h7.949a.538.538 0 100-1.076z"
      />
      <path
        className="prefix__a"
        d="M5.558 29.404a.538.538 0 00.538-.538v-12.55h4.542v12.55a.538.538 0 001.076 0v-7.471h4.243v7.471a.538.538 0 001.076 0v-8.008a.538.538 0 00-.538-.538h-4.781v-4.542a.538.538 0 00-.538-.538H5.558a.538.538 0 00-.538.538v13.088a.538.538 0 00.538.538zM19.364 29.404a.538.538 0 00.538-.538V6.156h4.243v22.71a.538.538 0 001.076 0v-17.63h4.542v17.63a.538.538 0 001.076 0V10.698a.538.538 0 00-.538-.538h-5.08V5.618a.538.538 0 00-.538-.538h-5.319a.538.538 0 00-.538.538v23.248a.538.538 0 00.538.538z"
      />
    </SvgIcon>
  );
};

export default StatisticsIcon;
