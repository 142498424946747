import React from "react";
import { Box, Grid, TableBody, TableCell } from "@material-ui/core";
import AppTableRow from "./table/AppTableRow";
import TableCellHead from "./table/TableCellHead";
import AppTable from "./table/AppTable";
import { ExportToExcel } from "../components/com";
const ConsumptionTable = ({
  consumptionData = [],
  isMonth,
  hasTotalHour,
  months = [],
  keys = [],
  id,
  table,
  filename
}) => {
  const _renderConsumptionTableBody = () => {
    console.log("consumption table body", consumptionData);

    return (
      <React.Fragment>
        {keys.map(key => (
          <AppTableRow key={key.value}>
            <TableCellHead size="small">{key.name}</TableCellHead>
            {consumptionData.map(item => (
              <TableCell key={item.name} align="center">
                {item[key.value]}
              </TableCell>
            ))}
          </AppTableRow>
        ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <AppTable id={table} size="small">
        <TableBody>
          <AppTableRow>
            {isMonth ? (
              <React.Fragment>
                <TableCellHead size="small">Mes</TableCellHead>
                {months.map((item, i) => (
                  <TableCell key={i.toString()} component="th" scope="row">
                    <Box fontWeight="fontWeightBold">{item}</Box>
                  </TableCell>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableCellHead size="small">Hora</TableCellHead>
                {[...new Array(24)].map((item, i) => (
                  <TableCell component="th" scope="row">
                    <Box fontWeight="fontWeightBold">{`${i + 1}:00`}</Box>
                  </TableCell>
                ))}
                {hasTotalHour && (
                  <TableCell component="th" scope="row">
                    <Box fontWeight="fontWeightBold">Total</Box>
                  </TableCell>
                )}
              </React.Fragment>
            )}
          </AppTableRow>
          {_renderConsumptionTableBody()}
        </TableBody>
      </AppTable>
      <Grid
        item
        xs={12}
        component={Box}
        /*  className={classes.content} */
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        {
          <ExportToExcel
            id={id}
            table={table}
            filename={filename}
            sheet="Hoja de datos"
          />
        }
      </Grid>
    </>
  );
};

export default ConsumptionTable;
