import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Container,
  Menu,
  MenuItem,
  Box
} from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import Line from "./Line";
import AvatarIcon from "./AvatarIcon";
import { navigate } from "@reach/router";

const useStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    container: {
      padding: "1.2rem 0"
    },
    bar: {
      borderBottomLeftRadius: 45,
      borderBottomRightRadius: 45,
      /* backgroundImage: "url(/images/bolitas.png)", */
      backgroundSize: "cover"
    },
    button: {
      color: "#262626",
      textTransform: "capitalize"
    },
    rightIcon: {
      marginLeft: theme.spacing(1)
    },
    triangles: {
      backgroundImage: "url(/images/triangles.png)",
      width: 737,
      height: 343,
      left: 0,
      bottom: 0,
      transform: "rotate(180deg)",
      zIndex: -1,
      position: "absolute"
    },
    line: {
      position: "absolute",
      bottom: 0,
      left: 0
    },
    relativeBox: {
      position: "relative"
    },
    footer: {
      overflow: "hidden",
      paddingTop: theme.spacing(30),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(10)
      }
    },
    iconWrapper: {
      background: "#fff",
      borderRadius: "50%",
      marginRight: theme.spacing(1),
      width: "40px",
      height: "40px"
    }
  };
});

const Dashboard = ({ children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    /* setAnchorEl(null); */
    localStorage.clear();
  };

  const handleLogOut = () => {
    handleClose();
    navigate("/");
  };

  useEffect(() => {
    return () => {
      setAnchorEl(null);
    };
  }, []);
  return (
    <div className={classes.relativeBox}>
      <AppBar
        elevation={0}
        className={classes.bar}
        color="secondary"
        position="static"
      >
        <Container className={classes.container} maxWidth="lg">
          <Toolbar>
            <Grid container alignItems="center" justify="space-between">
              <img
                style={{
                  cursor: "pointer"
                }}
                src="/images/logo.svg"
                width="170px"
                height="37px"
                onClick={() => navigate("/portal/dashboard/consumos")}
                alt="logo"
              />
              <Button
                variant="text"
                color="inherit"
                className={classes.button}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.iconWrapper}
                >
                  <AvatarIcon color="secondary" />
                </Box>
                Mi Cuenta
                <KeyboardArrowDown className={classes.rightIcon} />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogOut}>Cerrar sesión</MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
      <Box className={clsx(classes.relativeBox, classes.footer)}>
        <div className={classes.triangles} />
        <Line className={classes.line} />
      </Box>
    </div>
  );
};

export default Dashboard;
