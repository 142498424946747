import React from 'react';

const SvgComponent = props => (
  <svg width={12.915} height="100%" {...props}>
    <path fill="#2f3687" d="M0 0h12.915v154.286H0z" />
    <path fill="#73a6b1" d="M0 154.286h12.915v154.286H0z" />
    <path fill="#9a2423" d="M0 308.572h12.915v154.286H0z" />
    <path fill="#dc8f35" d="M0 462.857h12.915v154.286H0z" />
    <path fill="#f3ce11" d="M0 617.143h12.915v154.286H0z" />
    <path fill="#458c87" d="M0 771.428h12.915v154.286H0z" />
    <path fill="#30385c" d="M0 925.714h12.915V1080H0z" />
  </svg>
);

export default SvgComponent;
