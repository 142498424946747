import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {IconButton, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  roundButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const ArrowButtonRound = () => {
  const classes = useStyles();

  return (
    <IconButton className={classes.roundButton} aria-label="more" size="small">
      <KeyboardArrowLeftIcon fontSize="inherit" />
    </IconButton>
  );
};

export default ArrowButtonRound;
