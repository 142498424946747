import React, {useState, useEffect} from 'react';
import {Redirect} from '@reach/router';
import api from '../../services/api';

const ProtectedLink = ({children}) => {
  const [isAuthenticate, setIsAuthenticate] = useState(true);

  const reAuthenticate = async () => {
    try {
      await api.reAuthenticate();
      setIsAuthenticate(true);
    } catch (e) {
      setIsAuthenticate(false);
    }
  };

  useEffect(() => {
    reAuthenticate();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      {isAuthenticate ? children : <Redirect to="/" noThrow />}
    </React.Fragment>
  );
};

export default ProtectedLink;
