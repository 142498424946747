import React from "react";
/* Select Dependences */
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: "hidden",
    "& th, & td": {
      border: "0.3px solid rgba(0, 0, 0, 0.10)"
    }
  },
  tableHead: {
    background: "#2F368715 0% 0% no-repeat padding-box",
    color: "rgba(0,0,0,0.6)",
    letterSpacing: 0
  },
  tableHeadItem: {
    fontWeight: "bold",
    padding: "1.5rem 1.3375rem",
    fontSize: "0.8rem"
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  headline: {
    padding: "3rem 0 4.5rem 0"
  },
  searchIcon: {
    padding: "0.2rem",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    color: "#fff",
    boxSizing: "content-box"
  },
  field: {
    width: "100%",
    maxWidth: "535px"
  },
  tableCellOption: {
    background: "#F4922619 0% 0% no-repeat padding-box",
    padding: "0 10px"
  },
  roundButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  },
  actions: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  },
  largeHead: {
    minWidth: 200
  },
  /* Actales */
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1)
  }
}));
const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #2F3687",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#2F3687",
      boxShadow: "0 0 0 0.2rem #2F368725"
    }
  }
}))(InputBase);
const useLoadingCellStyle = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    display: "block"
  }
}));
const SelectOption = props => {
  const classes = useStyles();
  let { label } = props;
  return (
    <>
      {label && (
        <InputLabel htmlFor={props.id || "select-simple"}>{label}</InputLabel>
      )}
      <Select
        native
        value={props.value}
        onChange={props.onChange}
        input={<BootstrapInput name="age" id="age-customized-select" />}
        inputProps={{
          name: props.name,
          id: props.id || "select-simple"
        }}
      >
        {props.dataSource.map(option => {
          let valueField = props["valueField"] || "value";
          let displayField = props["displayField"] || "text";
          return (
            <option key={option[valueField]} value={option[valueField]}>
              {option[displayField]}
            </option>
          );
        })}
      </Select>
    </>
  );
};

export default SelectOption;
