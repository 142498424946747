import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
  Box,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
  },
  selectOutline: {
    borderRadius: 10,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
}));

const AppSelect = ({items = [], outline, label, ...props}) => {
  const classes = useStyles();

  const inputClasses = [classes.select, outline && classes.selectOutline];

  const _renderLabel = () => {
    if (!label) {
      return;
    }
    return (
      <Typography>
        <Box mb={1} fontWeight="fontWeightBold">
          {label}
        </Box>
      </Typography>
    );
  };

  return (
    <React.Fragment>
      {_renderLabel()}
      <FormControl className={inputClasses}>
        <Select {...props} disableUnderline>
          {items.map(({value, text}) => (
            <MenuItem value={value} key={value.toString()}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

AppSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string,
    }),
  ),
};

export default AppSelect;
