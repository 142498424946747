import React, { useEffect, useState } from "react";
import FiltersHeadline from "./FiltersHeadline";
import { Box, Grid, TableBody, TableCell, makeStyles } from "@material-ui/core";
import BasePaper from "./BasePaper";
import AppTableRow from "../table/AppTableRow";
import { moment } from "../../utils/Helper";
import TableCellHead from "../table/TableCellHead";
import AppTable from "../table/AppTable";
import {
  formatDate,
  getCurrentDate,
  subtractDays,
  subtractMonth
} from "../../modules/date";
import { typeEnergy, unityEnergy } from "../../constants/energy";
import api from "../../services/api";
import ConsumptionTable from "../ConsumptionTable";
import { decimal } from "../../utils/Helper";

import { ExportToExcel } from "../../components/com";

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "scroll",
    paddingBottom: theme.spacing(1),
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 6px 6px 6px!important"
    },
    "& .MuiTableCell-root": {
      fontSize: "0.700rem",
      textAlign: "center"
    }
  },
  row: {
    padding: "6px 6px 6px 6px"
  },
  total: {
    textAlign: "left!important"
  }
}));

const ConsumptionMatrix = ({ nic, origin }) => {
  const clasess = useStyle();
  let currentDate = getCurrentDate();

  const [startSelectedDate, setStartSelectedDate] = useState(
    moment().subtract(1, "months")
  );
  const [loading, setLoading] = useState(false);
  const [_origin, setOrigin] = useState();

  const [consumptionData, setConsumptionData] = useState([]);
  let [consumptionTotal, setConsumptionTotal] = useState(0);

  const [endSelectedDate, setEndSelectedDate] = useState(currentDate);

  let [type, setType] = React.useState(typeEnergy[0].value);

  const handleTypeChange = type => {
    let { value } = type.target;
    type = value;
    setType(value);
    fetchConsumptionMatrix(origin,type);
  };

  const hasResultInConsumptionResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetMatrizConsumoResponse"][
        "GetMatrizConsumoResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };

  const getResultInConsumptionResponse = response => {
    return response["s:Envelope"]["s:Body"]["GetMatrizConsumoResponse"][
      "GetMatrizConsumoResult"
    ]["a:Consumos"]["a:MatrizConsumo"];
  };

  const normalizeConsumptionData = (consumptions = []) => {
    let totalItems = [];

    console.log("Data: ", consumptions);
    const exclude = ["a:ORIGEN", "a:FECHA", "a:TIPO_ENERGIA", "a:COD_NIC"];

    let normalizeConsumption = consumptions.map(consumption => {
      let date = formatDate(consumption["a:FECHA"]["_text"]),
        consumptionItems,
        total;

      delete consumption["a:FECHA"];
      delete consumption["a:ORIGEN"];
      delete consumption["a:COD_NIC"];
      delete consumption["a:TIPO_ENERGIA"];

      /* Nueva FORMA */

      if (typeof consumptionItems === "undefined") consumptionItems = [];
      Object.keys(consumption).forEach((element, index) => {
        let hour = index + 1;
        let item = consumption[`a:VALOR${hour}`];
        let value = parseFloat(item["_text"]);
        /* console.log("--->Value: ", value); */
        consumptionItems.push(value);
      });

      /* 
      >_ ASI ESTABA ANTES
      consumptionItems = Object.values(consumption).map(item =>
        parseFloat(item["_text"])
      ); */

      total = consumptionItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );

      consumptionTotal += total;
      return {
        name: date,
        items: [...consumptionItems, total]
      };
    });

    // todo total row
    /*totalItems = [...new Array(25)].map(index => {});

    totalItems = normalizeConsumption.map((consumptionRow, index) => {
      for (let i; i < consumptionRow.items.length; i++) {}
      return value;
    });*/
    setConsumptionTotal(consumptionTotal);
    return [
      ...normalizeConsumption /* { name: "*Total", items: [consumptionTotal] } */
    ];
  };

  const fetchConsumptionMatrix = async (origin,type, start_date, end_date) => {
    start_date = start_date != null ? start_date : startSelectedDate;
    start_date = moment(start_date).format("YYYY-MM-DD");
    end_date = end_date != null ? end_date : endSelectedDate;
    end_date = moment(end_date).format("YYYY-MM-DD");
    consumptionTotal = 0;
    setConsumptionTotal(consumptionTotal);
    try {
      setLoading(true);
      let resp = await api.service("announcement-i").create({
        action: "GetMatrizConsumo",
        content: {
          "soapenv:Envelope": {
            _attributes: {
              "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
              "xmlns:tem": "http://tempuri.org/",
              "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
            },
            "soapenv:Header": {},
            "soapenv:Body": {
              "tem:GetMatrizConsumo": {
                _comment: [
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:"
                ],
                "tem:User": {
                  _comment: ["Optional:", "Optional:"],
                  "wcf:Password": {
                    _text: "2B09moR0"
                  },
                  "wcf:User": {
                    _text: "wstlmd"
                  }
                },
                "tem:NIC": {
                  _text: nic
                },
                "tem:ORIGEN": {
                  _text: origin
                },
                "tem:ENERGIA": {
                  _text: type.toUpperCase()
                },
                "tem:FECHAI": {
                  _text: start_date
                },
                "tem:FECHAF": {
                  _text: end_date
                }
              }
            }
          }
        }
      });

      /* AQUI consumptionTotal */
      if (hasResultInConsumptionResponse(resp)) {
        let results, normalizeResults;
        results = getResultInConsumptionResponse(resp);
        normalizeResults = normalizeConsumptionData(results);

        //console.log(normalizeResults);

        setConsumptionData(normalizeResults);

        //console.log("consumption matrix", consumptionData);
      } else {
        setConsumptionData([]);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };
  if (_origin !== origin) {
    try {
      fetchConsumptionMatrix(origin,type);
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
    setOrigin(origin);
  }
  useEffect(() => {
    /* try {
      fetchConsumptionMatrix(origin);
    } catch (e) {
      alert("ERROR: " + e.message);
    } */
    return () => {};
  }, [startSelectedDate, endSelectedDate, type, nic]);

  return (
    <BasePaper loading={loading}>
      <Grid container component={Box}>
        <Grid item component={Box} pb={4} xs={12}>
          <FiltersHeadline
            type={type}
            types={typeEnergy}
            title="Matriz de consumo"
            onChangeType={handleTypeChange}
            firstDate={startSelectedDate}
            secondDate={endSelectedDate}
            onChangeFirstDate={date => {
              setStartSelectedDate(date);
              fetchConsumptionMatrix(origin,type, date);
            }}
            onChangeSecondDate={date => {
              setEndSelectedDate(date);
              fetchConsumptionMatrix(origin,type, null, date);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          component={Box}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <AppTable size="small" id="matrix" className={clasess.root}>
            <TableBody>
              <AppTableRow>
                <TableCellHead size="small">Fecha/Hora</TableCellHead>
                {[...new Array(24)].map((item, i) => (
                  <TableCell
                    component="th"
                    scope="row"
                    /* style={{
                      padding: "4px 0px 4px 0px"
                    }} */
                  >
                    <Box fontWeight="fontWeightBold">{`${i + 1}:00`}</Box>
                  </TableCell>
                ))}
                <TableCell component="th" scope="row">
                  <Box fontWeight="fontWeightBold">Total</Box>
                </TableCell>
              </AppTableRow>
              {consumptionData.map(consumption => {
                //console.log("Row: |", consumption);
                return (
                  <AppTableRow className={clasess.row}>
                    <TableCellHead size="small">
                      {consumption.name}
                    </TableCellHead>
                    {consumption.items.map(item => (
                      <TableCell align="right">
                        {decimal(Number(item))}
                      </TableCell>
                    ))}
                  </AppTableRow>
                );
              })}
              <AppTableRow>
                <TableCellHead size="small">
                  {" "}
                  <Box fontWeight="fontWeightBold">Total Consumo</Box>
                </TableCellHead>
                <TableCell className={clasess.total} colSpan={25} align="left">
                  {decimal(consumptionTotal, {
                    symbol: `(${unityEnergy[type.toUpperCase()]})`
                  })}
                </TableCell>
              </AppTableRow>
            </TableBody>
          </AppTable>
          <ExportToExcel
            table="matrix"
            filename="Matriz de consumo"
            sheet="Consumo"
          />
          {/*<Link>
            <Box mt={2} fontWeight="fontWeightBold">
              Exportar datos a Excel
            </Box>
          </Link>*/}
        </Grid>
      </Grid>
    </BasePaper>
  );
};

export default ConsumptionMatrix;
