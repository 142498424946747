import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

const CustomButton = withStyles(theme => ({
  root: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    padding: '7px 37px',
    fontSize: '1.5625rem',
  },
}))(Button);

const AppButton = ({children, ...props}) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};

export default AppButton;
