import React, { useState, useEffect } from "react";
import {
  Hidden,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppTextField from "../components/AppTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AvatarIcon from "../components/AvatarIcon";
import LockIcon from "../components/LockIcon";
import AppButton from "../components/AppButton";
import VerticalLine from "../components/VerticalLine";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { navigate } from "@reach/router";
import qs from "qs";
import api from "../services/api";
import { reset_password, change_password } from "../services";
import { async } from "q";
import { message } from "antd";

const schema = yup.object().shape({
  username: yup.string().required("Usuario es requerido."),
  password: yup.string().required("Contraseña es requerido.")
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(/images/login@2x.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 4)
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    alignSelf: "flex-start"
  },
  welcomeTitle: {
    fontSize: 49,
    fontWeight: "bold",
    color: "#fff"
  },
  pill: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: 20,
    opacity: 1,
    color: "#DC8F35",
    padding: "12px 100px",
    letterSpacing: 0,
    fontWeight: "bold",
    fontSize: 28,
    lineHeight: 1
  },
  title: {
    fontSize: "3rem",
    letterSpacing: 0,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40
    }
  },
  logo: {
    width: "207px",
    height: "43px",
    alignSelf: "flex-end"
  },
  link: {
    textTransform: "capitalize",
    textDecoration: "underline",
    color: theme.palette.primary.main,
    fontWeight: "lighter",
    fontSize: "1rem"
  },
  titleWrapper: {
    alignSelf: "flex-end"
  },
  triangles: {
    backgroundImage: "url(/images/triangles.png)",
    width: 737,
    height: 343,
    right: 0,
    top: 0,
    zIndex: -1,
    position: "absolute"
  },
  line: {
    position: "absolute",
    right: 0,
    top: 0
  }
}));

const ResetPasswordForm = props => {
  let { handleSubmit, goToView } = props;
  const classes = useStyles();

  return (
    <Formik
      onSubmit={handleSubmit}
      /* validationSchema={schema} */
      initialValues={{ email: "" }}
      render={({ isSubmitting, handleSubmit }) => (
        <Form
          className={classes.form}
          onKeyPress={event => {
            event.key === "Enter" && handleSubmit();
          }}
        >
          <AppTextField
            variant="outlined"
            required
            hasFormik
            large
            fullWidth
            id="email"
            placeholder="Ingrese su correo electrónico"
            name="email"
            key="email"
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <AvatarIcon />
              </InputAdornment>
            }
          />
          <Box mt={2} mb={3} clone>
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  onClick={() => {
                    goToView("login");
                  }}
                  variant="text"
                  className={classes.link}
                >
                  ¿Ya tiene una cuenta?
                </Button>
              </Grid>
            </Grid>
          </Box>
          <AppButton
            type="submit"
            variant="contained"
            color="primary"
            /* disabled={isSubmitting} */
            className={classes.submit}
          >
            Enviar
          </AppButton>
        </Form>
      )}
    />
  );
};
const ChangePasswordForm = props => {
  let { handleSubmit, goToView } = props;
  const classes = useStyles();

  return (
    <Formik
      onSubmit={handleSubmit}
      /* validationSchema={schema} */
      initialValues={{ email: "" }}
      render={({ isSubmitting, handleSubmit }) => (
        <Form
          className={classes.form}
          onKeyPress={event => {
            event.key === "Enter" && handleSubmit();
          }}
        >
          <AppTextField
            htmlType="password"
            type="password"
            variant="outlined"
            required
            hasFormik
            large
            fullWidth
            id="password"
            placeholder="Ingrese su nueva contraseña"
            name="password"
            key="password"
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
          />
          <AppTextField
            htmlType="password"
            type="password"
            variant="outlined"
            required
            hasFormik
            large
            fullWidth
            id="rp_password"
            placeholder="Repetir contraseña"
            name="rp_password"
            key="rp_password"
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
          />
          <Box mt={2} mb={3} clone>
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  onClick={() => {
                    goToView("login");
                  }}
                  variant="text"
                  className={classes.link}
                >
                  ¿Ya tiene una cuenta?
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    goToView("resetpassword");
                  }}
                  variant="text"
                  className={classes.link}
                >
                  ¿No te llegó correo?
                </Button>
              </Grid>
            </Grid>
          </Box>
          <AppButton
            type="submit"
            variant="contained"
            color="primary"
            /* disabled={isSubmitting} */
            className={classes.submit}
          >
            Enviar
          </AppButton>
        </Form>
      )}
    />
  );
};
export default function SignInSide(props) {
  const [view, setView] = useState("login");
  const [token, setToken] = useState();
  const classes = useStyles();

  const handleSubmit = async ({ username, password }, { resetForm }) => {
    try {
      await api.authenticate({
        strategy: "local",
        email: username,
        password: password
      });
      navigate("/portal/dashboard/consumos");
    } catch (e) {
      alert(e.message);
    }
  };
  const handleResetPassword = async params => {
    reset_password(params)
      .then(response => {
        setView("login");
        message.info("Se ha enviado un correo para cambiar su contraseña");
      })
      .catch(err => message.error(err.message));
  };
  const handleChangePassword = async params => {
    let { password, rp_password } = params;
    if (password !== rp_password) return message.error("Las contraseñas no coinciden");
    change_password(token, params)
      .then(response => {
        setView("login");
        message.info("Se ha actualizado su contraseña");
      })
      .catch(err => message.error(err.message));
  };

  useEffect(() => {
    let {
      location: { search }
    } = props;
    search = qs.parse(search.replace(/\?/, ""));
    if ("token" in search && search["token"]) {
      setToken(search["token"]);
      setView("changepassword");
    }
    return () => {};
  }, []);
  return (
    <Grid
      container
      justify="space-between"
      component="main"
      className={classes.root}
    >
      <Hidden smDown>
        <Grid
          item
          xs={false}
          alignItems="center"
          justify="center"
          md={6}
          component={Box}
          display="flex"
          direction="column"
          className={classes.image}
        >
          <Typography
            className={classes.welcomeTitle}
            component="h1"
            color="white"
          >
            Bienvenido al Portal de
          </Typography>
          <div className={classes.pill}>
            Clientes No Regulados
          </div>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        md={5}
        pr={{ xs: 0, md: 5 }}
        display="flex"
        direction="column"
        justify="center"
        component={Box}
        elevation={0}
        square
      >
        <div className={classes.paper}>
          <Box mb={4} className={classes.titleWrapper}>
            <Typography
              className={classes.title}
              component="h1"
              color="primary"
            >
              {view === "login"
                ? "Inicia sesión"
                : view !== "changepassword"
                ? "Solicitar Contraseña"
                : "Cambiar Contraseña"}
            </Typography>
          </Box>
          {view === "login" ? (
            <Formik
              onSubmit={handleSubmit}
              validationSchema={schema}
              initialValues={{ username: "", password: "" }}
              render={({ isSubmitting, handleSubmit }) => (
                <Form
                  className={classes.form}
                  onKeyPress={event => {
                    event.key === "Enter" && handleSubmit();
                  }}
                >
                  <AppTextField
                    variant="outlined"
                    required
                    hasFormik
                    large
                    fullWidth
                    id="username"
                    placeholder="E-Mail"
                    name="username"
                    key="username"
                    autoFocus
                    startAdornment={
                      <InputAdornment position="start">
                        <AvatarIcon />
                      </InputAdornment>
                    }
                  />
                  <Box mt={1}>
                    <AppTextField
                      variant="outlined"
                      key="password"
                      required
                      fullWidth
                      hasFormik
                      large
                      name="password"
                      placeholder="Contraseña"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      startAdornment={
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box mt={2} mb={3} clone>
                    <Grid container justify="space-between">
                      {/*  <Grid item>
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Mantener la sesión iniciada"
                      />
                    </Grid> */}
                      {/* <Grid item>
                        <Button
                          onClick={() => {
                            setView("resetpassword");
                          }}
                          variant="text"
                          className={classes.link}
                        >
                          ¿Olvidaste tu contraseña?
                        </Button>
                      </Grid> */}
                    </Grid>
                  </Box>
                  <AppButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    /* disabled={isSubmitting} */
                    className={classes.submit}
                  >
                    Iniciar sesión
                  </AppButton>
                </Form>
              )}
            />
          ) : view !== "changepassword" ? (
            <ResetPasswordForm
              handleSubmit={handleResetPassword}
              goToView={view => {
                setView(view);
              }}
            />
          ) : (
            <ChangePasswordForm
              handleSubmit={handleChangePassword}
              goToView={view => {
                setView(view);
              }}
            />
          )}
          <Box mt={10} clone>
            <img
              className={classes.logo}
              src="/images/logo.svg"
              alt="electricaribe"
            />
          </Box>
        </div>
      </Grid>
      <div className={classes.triangles} />
      <VerticalLine className={classes.line} />
    </Grid>
  );
}
