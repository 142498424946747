import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Paper,
  Box,
  makeStyles,
  Button,
  Input
} from "@material-ui/core";
import { navigate } from "@reach/router";
import ConsumptionPerHour from "../components/consumption/ConsumptionPerHour";
import "date-fns";
import DailyConsumption from "../components/consumption/DailyConsumption";
import AccumulatedMonthly from "../components/consumption/AccumulatedMonthly";
import MonthlyConsumption from "../components/consumption/MonthlyConsumption";
import ConsumptionMatrix from "../components/consumption/ConsumptionMatrix";
import ArrowButtonRound from "../components/ArrowButtonRound";
import QueryData from "../components/consumption/QueryData";
import SelectOption from "../components/com/Select";
import api from "../services/api";
import { message } from "antd";

const origin_types = [
  {
    text: "Telemedidos",
    value: "1"
  },
  {
    text: "Facturados (AENC)",
    value: "2"
  }
];

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 2,
    position: "relative"
  },
  title: {
    fontWeight: "bold"
  },
  back: {
    textTransform: "capitalize"
  }
}));

const ConsumptionDetail = ({ id }) => {
  const classes = useStyles();
  let [origin, setOrigin] = useState("1");
  const [nic, setNic] = useState({});

  const handleChange = event => {
    let { value } = event.target;
    setOrigin(value);
    console.log(value);
  };
  const onQueryChange = (nic, data) => {
    console.log(nic, data);
  };
  const getNic = async nic => {
    let resp = await api.service("announcement-i").create({
      action: "GetConsumoAct",
      content: {
        "soapenv:Envelope": {
          _attributes: {
            "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
            "xmlns:tem": "http://tempuri.org/",
            "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
          },
          "soapenv:Header": {},
          "soapenv:Body": {
            "tem:GetConsumoAct": {
              _comment: ["Optional:", "Optional:", "Optional:"],
              "tem:User": {
                _comment: ["Optional:", "Optional:"],
                "wcf:Password": {
                  _text: "2B09moR0"
                },
                "wcf:User": {
                  _text: "wstlmd"
                }
              },
              "tem:NIC": {
                _text: nic
              },
              "tem:ORIGEN": {
                _text: origin
              }
            }
          }
        }
      }
    });
    resp =
      resp["s:Envelope"]["s:Body"]["GetConsumoActResponse"][
        "GetConsumoActResult"
      ]["a:Consumos"]["a:ConsumoAct"];
    return resp[0] || {};
  };
  useEffect(async () => {
    let resp = await getNic(id);
    if ("a:DIRECCION" in resp) setNic(resp);
    return () => {};
  }, []);
  return (
    <Container
      component={Box}
      mt={7}
      maxWidth="xl"
      className={classes.container}
    >
      <Grid container component={Box} mb={4} justify="space-between">
        <Grid item>
          <Box mb={1}>
            <Typography variant="h2">Portal Telemedidos</Typography>
          </Box>
          {nic["a:DIRECCION"] && (
            <Typography variant="subtitle1">
              {nic["a:DIRECCION"]["_text"]}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              window.location.href = "/portal/dashboard/consumos";

              /* navigate("/portal/dashboard/consumos") */
            }}
            variant="text"
          >
            <ArrowButtonRound />
            <Box className={classes.back} fontWeight="fontWeightBold" pl={1}>
              volver
            </Box>
          </Button>
        </Grid>
      </Grid>
      <Grid
        className={classes.headline}
        container
        /*  alignItems="center" */
        justify="space-between"
      >
        <Grid
          item
          span={24}
          style={{
            margin: 10
          }}
        >
          {origin === "2" && (
            <Box>
              <p>
                “La información Telemedida corresponde a la extraída del
                medidor; la información facturada corresponde a lo aportado por
                XM (Expertos del Mercado), en ausencia de reporte de información
                diaria. Por lo anterior lo enseñado en la página puede ser
                diferente al momento de expedir la factura”
              </p>
            </Box>
          )}
          <SelectOption
            label="Origen de Datos"
            dataSource={origin_types}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ConsumptionPerHour origin={origin} nic={id} />
      <Grid container spacing={4} component={Box} px={{ xs: 1, md: 7 }} py={7}>
        <Grid item xs={12} md={6}>
          <DailyConsumption origin={origin} nic={id} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AccumulatedMonthly origin={origin} nic={id} />
        </Grid>
      </Grid>
      <MonthlyConsumption origin={origin} nic={id} />
      <ConsumptionMatrix origin={origin} nic={id} />
      <QueryData origin={origin} nic={id} onChange={onQueryChange} />
    </Container>
  );
};

export default ConsumptionDetail;
