import React from 'react';
import {makeStyles, TableCell} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    lineHeight: 1.2,
    background: '#2F368715 0% 0% no-repeat padding-box',
    color: 'rgba(0,0,0,0.6)',
    letterSpacing: 0,
  },
  medium: {
    padding: '1rem 1.3375rem',
  },
}));

const TableCellHead = ({
  align = 'center',
  size = 'medium',
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <TableCell
      align={align}
      className={clsx(classes.root, className, classes[size])}>
      {children}
    </TableCell>
  );
};

TableCellHead.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default TableCellHead;
