import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    maxWidth: 1314,
  },
}));

const NoFound = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" my={6} alignItems="center">
      <img src="/images/404.png" className={classes.image} alt="No found" />
    </Box>
  );
};

export default NoFound;
