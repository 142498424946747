import feathers from '@feathersjs/client';

const client = feathers();

client.configure(
  feathers
    .rest('https://telemedios-back.energiacaribesol.co')
    .fetch(window.fetch.bind(window)),
);

client.configure(
  feathers.authentication({
    path: 'authentication',
    entity: 'user',
    service: 'users',
    cookie: 'feathers-jwt',
    storageKey: 'feathers-jwt',
    storage: window.localStorage,
  }),
);

export default client;
