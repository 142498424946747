import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  AreaChart,
  XAxis,
  YAxis,
  Area
} from "recharts";
import BasePaper from "./BasePaper";
import { unityEnergy } from "../../constants/energy";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import DatePicker from "../DatePicker";
import AppSelect from "../AppSelect";
import FiltersHeadline from "./FiltersHeadline";
import { typeEnergy } from "../../constants/energy";
import {
  formatDate,
  getCurrentDate,
  getMonth,
  getYear,
  formatMonth,
  subtractDays
} from "../../modules/date";
import { moment } from "../../utils/Helper";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
  options: {
    width: "80%"
  },
  chart: {
    height: 300
  }
}));

const AccumulatedMonthly = ({ nic, origin = "1", isAccumulated = false }) => {
  const classes = useStyles();
  let currentDate = getCurrentDate();
  let [startSelectedDate, setStartSelectedDate] = useState(
    moment().subtract(4, "months")
  );
  const [loading, setLoading] = useState(false);
  let [endSelectedDate, setEndSelectedDate] = useState(currentDate);
  let [type, setType] = useState(typeEnergy[0].value.toLowerCase());
  let [consumptionData, setConsumptionData] = useState([]);
  let [accumulated, setAccumulated] = useState([]);
  let [label, setLabel] = useState(`Acumulado ${unityEnergy["AS"]}`);
  const hasResultInConsumptionResponse = response => {
    return (
      response["s:Envelope"]["s:Body"]["GetConsumoMesResponse"][
        "GetConsumoMesResult"
      ]["a:codRespuesta"]["_text"] === "1"
    );
  };
  const getResultInConsumptionResponse = response => {
    return response["s:Envelope"]["s:Body"]["GetConsumoMesResponse"][
      "GetConsumoMesResult"
    ]["a:Consumos"]["a:ConsumoMes"];
  };

  const getType = type => {
    let energyType = typeEnergy.filter(item => item.value === type);
    if (energyType.length > 0) return energyType[0].text;
    return "";
  };
  const normalizeConsumptionData = (consumption = []) => {
    console.log(">>> Consumo: ", consumption);
    if (Array.isArray(consumption)) {
      return consumption.map(item => {
        let name = `${item["a:MES"]["_text"]} - ${item["a:ANNO"]["_text"]}`;
        return {
          name,
          value: parseFloat(item["a:TOTAL_MES"]["_text"])
        };
      });
    } else {
      return [
        {
          name: `${consumption["a:MES"]["_text"]} - ${
            consumption["a:ANNO"]["_text"]
          }`,
          value: parseFloat(consumption["a:TOTAL_MES"]["_text"])
        }
      ];
    }
  };
  const handleTypeChange = type => {
    let { value } = type.target;
    type = value;
    setType(value);
    setLabel(`Acumulado (${unityEnergy[value.toUpperCase()]})`);
    fetchMonthlyConsumption(origin, type);
  };
  
  const fetchMonthlyConsumption = async (
    origin,
    type,
    start_date,
    end_date
  ) => {
    start_date = start_date != null ? start_date : startSelectedDate;
    start_date = moment(start_date).format("M");
    end_date = end_date != null ? end_date : endSelectedDate;
    end_date = moment(end_date).format("M");

    try {
      setLoading(true);
      let resp = await api.service("announcement-i").create({
        action: "GetConsumoMes",
        content: {
          "soapenv:Envelope": {
            _attributes: {
              "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
              "xmlns:tem": "http://tempuri.org/",
              "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
            },
            "soapenv:Header": {},
            "soapenv:Body": {
              "tem:GetConsumoMes": {
                _comment: [
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:"
                ],
                "tem:User": {
                  _comment: ["Optional:", "Optional:"],
                  "wcf:Password": {
                    _text: "2B09moR0"
                  },
                  "wcf:User": {
                    _text: "wstlmd"
                  }
                },
                "tem:NIC": {
                  _text: nic
                },
                "tem:ORIGEN": {
                  _text: origin
                },
                "tem:ENERGIA": {
                  _text: type.toUpperCase()
                },
                "tem:ANNO": {
                  _text: getYear(endSelectedDate)
                },
                "tem:MESI": {
                  _text: start_date
                },
                "tem:MESF": {
                  _text: end_date
                }
              }
            }
          }
        }
      });
      console.log("accumulated monthly resp", resp);
      if (hasResultInConsumptionResponse(resp)) {
        let results = getResultInConsumptionResponse(resp);
        console.log("accumulated monthly", results);
        setConsumptionData(normalizeConsumptionData(results));
        let data = normalizeConsumptionData(results);
        accumulated = [];
        data = data.reduce((prev, current, index) => {
          if (accumulated.length > 0) {
            current["value"] = prev["value"] + current["value"];
          }
          accumulated.push(current);
          return current;
        }, accumulated);
        accumulated = accumulated.map(item => {
          console.log("FECHA:: ", item["name"]);
          return {
            name: moment(`${item["name"]}`, "M - YYYY").format(
              "MMMM [de] YYYY"
            ),
            value: item["value"]
          };
        });
        setAccumulated(accumulated);
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const AreaGraphic = props => (
    <AreaChart
      width={600}
      height={300}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
      data={props.data}
    >
      <XAxis dataKey="name" />
      <Tooltip />
      <YAxis />
      <CartesianGrid stroke="#f5f5f5" />
      <Area
        type="monotone"
        name={`${getType(type)}`}
        dataKey="value"
        stroke="#FF925C"
        fill="#FF925C"
      />
      <Legend />
    </AreaChart>
  );
  useEffect(() => {
    fetchMonthlyConsumption(origin, type);
    //soap();
    return () => {};
  }, [startSelectedDate, endSelectedDate, type, nic]);

  return (
    <BasePaper loading={loading} className={classes.root}>
      <FiltersHeadline
        type={type}
        types={typeEnergy}
        title="Acumulado mensual"
        onChangeType={handleTypeChange}
        firstDate={startSelectedDate}
        secondDate={endSelectedDate}
        onChangeFirstDate={date => {
          fetchMonthlyConsumption(origin, type, date);
          setStartSelectedDate(date);
        }}
        onChangeSecondDate={date => {
          setEndSelectedDate(date);
          fetchMonthlyConsumption(origin, type, null, date);
        }}
      />
      <div className={classes.chart}>
        <ResponsiveContainer>
          <AreaGraphic data={accumulated} />
          {/* <BarChart
            width={500}
            height={300}
            data={consumptionData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" name="Consumo" fill="#A4D7B7" />
          </BarChart> */}
        </ResponsiveContainer>
      </div>
    </BasePaper>
  );
};

export default AccumulatedMonthly;
