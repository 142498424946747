import React, { useEffect, useState } from "react";
import BasePaper from "./BasePaper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Container,
  makeStyles
} from "@material-ui/core";
import AppTextField from "../AppTextField";
import AppSelect from "../AppSelect";
import AppButton from "../AppButton";
import api from "../../services/api";
import { formatDate } from "../../modules/date";
import { moment } from "../../utils/Helper";
const useStyle = makeStyles(theme => ({
  smallInput: {
    maxWidth: 126
  },
  submit: {
    display: "block",
    margin: "0 auto"
  },
  textarea: {
    minHeight: 164,
    backgroundColor: "#fff"
  },
  list: {
    maxHeight: "200px",
    minWidth: "200px",
    overflow: "hidden",
    width: "100%",
    border: "1px solid #2F3687",
    borderRadius: "10px",
    "&.MuiList-root": {
      overflowY: "auto",
      height: "100%"
    }
  },

  itemList: {
    height: "100%",
    overflowY: "auto"
  }
}));
const initDate = moment("02-02-2018");
const monthInit = initDate.format("M");
const yearInit = initDate.format("YYYY");
const currentDate = moment();

const countMounths = currentDate.diff(initDate, "months") + 1;
var year = Number(yearInit);
var mount = Number(monthInit);
let periodos = [];
for (var i = monthInit; i < countMounths; ++i) {
  var current = null;
  if (monthInit === i) {
    current = moment([year, mount - 1]);
    periodos.push(current);
  }
  mount++;
  current = moment([year, mount - 1]);
  periodos.push(current);
  if (mount % 12 == 0) {
    mount = 1;
    year += 1;
    current = moment([year, mount - 1]);
    periodos.push(current);
  }
}
periodos = periodos.map(item => {
  let value = item.format("YYYYM");
  return {
    text: item.format("YYYYMM"),
    value
  };
});
const QueryData = props => {
  let { nic, origin = "1", onChange } = props;
  const classes = useStyle();
  const [variableM, setVariableM] = useState({});
  const [period_current, setPeriodCurrentMont] = useState(null);
  const [start_period, setStartPeriodOfCompletion] = useState(null);
  const [_origin, setOrigin] = useState();
  let defaultPeriod = moment()
    .subtract(2, "months")
    .format("YYYYM");
  let [periodo, setPeriodo] = useState(defaultPeriod);
  let [periodos_pen, setPeriodos] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const getResultInVariableM = response =>
    response["s:Envelope"]["s:Body"]["GetVariableMResponse"][
      "GetVariableMResult"
    ]["a:Datos"]["a:Variable_M"];
  const normalizeVariableMData = data => {
    return {
      nic: data["a:COD_NIC"]["_text"],
      sic: data["a:COD_SIC"]["_text"],
      description: data["a:DESCRIPCION"]["_text"],
      r1Completion: data["a:DIAS_PEN_R1"]["_text"],
      r2Completion: data["a:DIAS_PEN_R2"]["_text"],
      rmCompletion: data["a:DIAS_PEN_RM"]["_text"],
      penaltyPeriods: data["a:MESES_PENAL"]["_text"],
      previousM: data["a:M_ANTERIOR"]["_text"],
      mPeriodo: data["a:M_PERIODO"]["_text"],
      diasPenPeriodo: data["a:DIAS_PEN_PERIODO"]["_text"],
      reseteoAnterior: data["a:RESETEO_ANTERIOR"]["_text"],
      reseteoPeriodo: data["a:RESETEO_PERIODO"]["_text"],
      startPeriodOfCompletion: data["a:PERIODO_INICIAL"]["_text"],
      periodCurrent: data["a:PERIODO_ACTUAL"]["_text"]
    };
  };
  const GetMesesPen = async params => {
    const resp = await api.service("announcement-i").create({
      action: "GetMesesPen",
      content: {
        "soapenv:Envelope": {
          _attributes: {
            "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
            "xmlns:tem": "http://tempuri.org/",
            "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
          },
          "soapenv:Header": {},
          "soapenv:Body": {
            "tem:GetMesesPen": {
              _comment: [
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:",
                "Optional:"
              ],
              "tem:User": {
                _comment: ["Optional:", "Optional:"],
                "wcf:Password": {
                  _text: "2B09moR0"
                },
                "wcf:User": {
                  _text: "wstlmd"
                }
              },
              "tem:NIC": {
                _text: nic
              },
              "tem:ORIGEN": {
                _text: origin
              }
            }
          }
        }
      }
    });
    try {
      let result =
        resp["s:Envelope"]["s:Body"]["GetMesesPenResponse"][
          "GetMesesPenResult"
        ]["a:Datos"]["a:Meses_Pen"];

      result = result.map(item => {
        return {
          text: item["a:PERIODOS"]["_text"],
          value: item["a:PERIODOS"]["_text"]
        };
      });
      setPeriodos(result);
    } catch (e) {
      /*  setError(true); */
    } finally {
      setLoading(false);
    }
    //const normalize = normalizeVariableMData(result);
  };
  const fetchConsumption = async (origin, periodo) => {
    let year = String(moment(periodo, "YYYYM").format("YYYY"));
    let month = String(moment(periodo, "YYYYM").format("M"));
    try {
      setLoading(true);
      setError(false);
      const resp = await api.service("announcement-i").create({
        action: "GetVariableM",
        content: {
          "soapenv:Envelope": {
            _attributes: {
              "xmlns:soapenv": "http://schemas.xmlsoap.org/soap/envelope/",
              "xmlns:tem": "http://tempuri.org/",
              "xmlns:wcf": "http://schemas.datacontract.org/2004/07/WCFSGD"
            },
            "soapenv:Header": {},
            "soapenv:Body": {
              "tem:GetVariableM": {
                _comment: [
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:",
                  "Optional:"
                ],
                "tem:User": {
                  _comment: ["Optional:", "Optional:"],
                  "wcf:Password": {
                    _text: "2B09moR0"
                  },
                  "wcf:User": {
                    _text: "wstlmd"
                  }
                },
                "tem:NIC": {
                  _text: nic
                },
                "tem:ORIGEN": {
                  _text: origin
                },
                "tem:ANNO": {
                  _text: year
                },
                "tem:MES": {
                  _text: month
                }
              }
            }
          }
        }
      });
      const result = getResultInVariableM(resp);
      const normalize = normalizeVariableMData(result);

      let period_start = moment(
        moment(moment(periodo, "YYYYM").subtract(1, "months"), "YYYYM")
      )
        .format("MMMM")
        .toUpperCase();
      let current_period_month = moment(moment(periodo, "YYYYM"))
        .format("MMMM")
        .toUpperCase();

      /* Set Month Name */
      setStartPeriodOfCompletion(period_start);
      setPeriodCurrentMont(current_period_month);
      /* setPeriodo(normalize.startPeriodOfCompletion); */
      setVariableM(normalize);
      /* Get Month Pen */
      GetMesesPen();
      //console.log("variableM: ", { normalize });
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = event => {
    let { value } = event.target;
    setPeriodo(value);
    fetchConsumption(origin, value);
    if (onChange) {
      onChange(value, variableM);
    }
    //console.log("Value: ", value);
  };
  if (_origin != origin) {
    fetchConsumption(origin, periodo);
    setOrigin(origin);
  }
  useEffect(() => {
    /* fetchConsumption(origin, periodo); */
    return () => {};
  }, []);
  return (
    <BasePaper
      mt={6}
      error={error}
      loading={loading}
      onRefresh={() => fetchConsumption(origin, periodo)}
    >
      <Container>
        <Typography>
          <Box
            display="flex"
            justifyContent="center"
            mb={4}
            fontWeight="fontWeightBold"
          >
            Datos para Consulta
          </Box>
        </Typography>
        <Box mb={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              {/* Quien lea esta vaina, el diseño tenia unos select gg !!!!*/}
              <AppTextField
                disabled
                inputProps={{
                  name: "nic",
                  id: "nic-readonly",
                  readOnly: true
                }}
                label="NIC"
                outline
                value={nic}
                /* items={[{ value: nic, text: nic }]} */
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppTextField
                disabled
                label="Descripción Cliente"
                inputProps={{
                  name: "description",
                  id: "description-readonly",
                  readOnly: true
                }}
                value={variableM.description}
                /* items={[{ value: 1, text: variableM.description }]} */
                outline
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppTextField
                disabled
                label="COD SIC"
                value={variableM.sic}
                outline
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AppSelect
                onChange={handleChange}
                label="Periodo"
                outline
                value={periodo}
                items={periodos}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider variant="middle" />
        <Box my={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography>
                <Box textAlign="center" mb={4} fontWeight="fontWeightBold">
                  Datos para Consulta
                </Box>
              </Typography>
              <Box mt={4}>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>(M) Aplicar en periodo</Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField value={variableM.mPeriodo} disabled />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>
                        Días con Penalización en el Periodo
                      </Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField value={variableM.diasPenPeriodo} disabled />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>
                        Días Penalización Reactiva / Induct.
                      </Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField disabled value={variableM.r2Completion} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>
                        Días Penalización Reactiva / Capact.
                      </Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField disabled value={variableM.r1Completion} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>
                        Días Penalización Reactiva / Mixta
                      </Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField disabled value={variableM.rmCompletion} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid item>
                      <Typography>Periodo Inicio Penalización</Typography>
                    </Grid>
                    <Grid item className={classes.smallInput}>
                      <AppTextField
                        disabled
                        value={variableM.startPeriodOfCompletion}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>
                <Box textAlign="center" mb={4} fontWeight="fontWeightBold">
                  Datos para Consulta
                </Box>
              </Typography>

              <Grid container spacing={3} justify="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    <Box mb={8} fontWeight="fontWeightBold">
                      Periodos con <br /> Penalización
                    </Box>
                  </Typography>
                  <AppTextField disabled value={variableM.penaltyPeriods} />
                </Grid>
                <Grid item>
                  <List dense={false} className={classes.list}>
                    {periodos_pen.map(item => {
                      return (
                        <ListItem>
                          <ListItemText
                            primary={item.text}
                            /* secondary={secondary ? "Secondary text" : null} */
                          ></ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="middle" />
        <Box my={5}>
          <Typography>
            <Box textAlign="center" mb={4} fontWeight="fontWeightBold">
              Contadores
            </Box>
          </Typography>
          <Container maxWidth="md">
            <Grid container>
              <Grid xs={12} md={6} item>
                <Grid alignItems="center" justify="space-between" container>
                  <Grid xs={6} item>
                    <Typography>Valor (M) </Typography>
                  </Grid>
                  <Grid xs={6} item component={Box} spacing={2} display="flex">
                    <Box mr={2}>
                      <AppTextField
                        label={start_period}
                        disabled
                        value={variableM.previousM}
                      />
                    </Box>
                    <Box mr={2}>
                      <AppTextField
                        label={period_current}
                        value={variableM.mPeriodo}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Grid alignItems="center" justify="space-between" container>
                    <Grid xs={6} item>
                      <Typography>Contador Reseteo</Typography>
                    </Grid>
                    <Grid
                      xs={6}
                      item
                      component={Box}
                      spacing={2}
                      display="flex"
                    >
                      <Box mr={2}>
                        <AppTextField
                          disabled
                          value={variableM.reseteoAnterior}
                        />
                      </Box>
                      <AppTextField disabled value={variableM.reseteoPeriodo} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* <Grid xs={12} md={6} item>
                <AppButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Analizar Cliente
                </AppButton>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      </Container>
    </BasePaper>
  );
};

export default QueryData;
