import React from 'react';

import {makeStyles, Typography, Button, Box} from '@material-ui/core';

import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#f2f2f2',
    zIndex: 999,
  },
}));

const NetworkError = ({onRefresh}) => {
  const classes = useStyle();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Typography color="white">
        <Box component="span" fontWeight="fontWeightBold">
          Ups, ocurrío un error.
        </Box>
      </Typography>

      <Button onClick={onRefresh}>Reintentar</Button>
    </Box>
  );
};

export default NetworkError;
